export const navigation = [
  {
    name: "Brand",
    links: [
      {
        name: "Get Started",
        link: "/",
      },
      {
        name: "Principles",
        link: "/principles",
      },
    ],
  },
  {
    name: "Messaging",
    links: [
      {
        name: "Introduction",
        link: "/introduction",
      },
      {
        name: "Brand vision",
        link: "/brand-vision",
      },
      {
        name: "Voice chart",
        link: "/voice-chart",
      },
      {
        name: "Do's & don'ts",
        link: "/dos-and-dont",
      },
      {
        name: "Case studies",
        link: "/case-studies",
      },
      {
        name: "Grammar & mechanics",
        link: "/grammar-and-mechanics",
      },
      {
        name: "Word list",
        link: "/word-list",
      },
    ],
  },
  {
    name: "Branding",
    links: [
      {
        name: "Logotype",
        link: "/logotype",
      },
      {
        name: "Color",
        link: "/color",
      },
      {
        name: "Typography",
        link: "/typography",
      },
      {
        name: "Icons",
        link: "/icons",
      },
      {
        name: "Photography",
        link: "/photography",
      },
      {
        name: "Illustration",
        link: "/illustration",
      },

      {
        name: "Sonic branding",
        link: "/sonic-branding",
      },

      {
        name: "Motion",
        link: "/motion",
      },
      {
        name: "3D Elements",
        link: "/3d-elements",
      },
    ],
  },
  {
    name: "System in use",
    links: [
      {
        name: "Social Media",
        link: "/social-media",
      },
    ],
  },
];

export const navigation2 = [
  {
    name: "Design System",
    links: [
      {
        name: "Design System",
        link: "/design-system",
      },
    ],
  },
];
