//React
import React from "react";

// Components
import SEO from "components/seo/";

// Logos
import { ReactComponent as Logo } from "assets/icons/indiwhite.svg";
import { ReactComponent as SmallLogo } from "assets/icons/logotype/small-logo-black.svg";

// Motion animacion
import { ReactComponent as AnimacionCol1 } from "assets/icons/illustration/icons-columna1.svg";
import { ReactComponent as AnimacionCol2 } from "assets/icons/illustration/icons-columna2.svg";

// Animation personajes
import { ReactComponent as Animation } from "assets/icons/motion/illustrationcharacters.svg";

// Layout
import Layout from "layouts/layout-primary";

const Motion = () => {
  return (
    <Layout>
      <SEO
        title="Branded Animations & Motiongraphs - Indicius Brand Website"
        description="Guidelines for animating logo, icons, and graphics"
      />
      <div className="motion">
        <h1 className="motion__title">Motion</h1>
        <p>
          The purpose of this guide is to standardize our illustrations. Use the
          guide as a reference when you are creating new illustrations or icons.
        </p>
        <h3 className="motion__subtitle">Logo Animation</h3>
        <p>
          The logo is animated using strokes. They can be made up of one or more
          colors, as long as they are the brand colors. This resource is
          scalable and can be adapted to any text or shape you like; for
          example, a slogan. It’s also “developer-friendly” as it can be
          exported in .json format.
        </p>

        <div className="motion__logo">
          <Logo />
        </div>

        <div className="motion__rectangulos">
          <div className="rectangulo">
            <Logo />
          </div>
          <div className="rectangulo bg--midnight">
            <Logo />
          </div>
          <div className="rectangulo bg--stout">
            <Logo />
          </div>
        </div>

        <div className="motion__cuadrados">
          <div className="cuadrado fill--white">
            <SmallLogo />
          </div>
          <div className="cuadrado bg-white">
            <SmallLogo />
          </div>

          <div className="cuadrado bg--cream">
            <SmallLogo />
          </div>
          <div className="cuadrado bg--supernova">
            <SmallLogo />
          </div>
        </div>

        <div className="motion__animacion">
          <h3>Miscellaneous Animations</h3>
          <p>
            Miscellaneous Animations Shapes and miscellaneous images are
            animated using the laws of physics, both individually and when they
            interact with each other: Gravity, bounce, speed, etc. Solids do not
            changes their original shape: They do not become warped.
          </p>
          <div className="col__animacion">
            <AnimacionCol1 />
            <AnimacionCol2 />
          </div>
        </div>

        <div className="motion__animacion__personajes">
          <h3>Character animation</h3>
          <p>
            The "rubberhose" style of animation should be used for arms and
            legs.
          </p>
          <Animation />
        </div>
      </div>
    </Layout>
  );
};

export default Motion;
