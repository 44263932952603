import Layout from "layouts/layout-primary";

// Components
import SEO from "components/seo/";

// LOGO HEADER
import { ReactComponent as Indilogo } from "assets/icons/logotype/logo-1.svg";

// LOGO BACKGROUNDS EXAMPLES
import { ReactComponent as Indiblack } from "assets/icons/logotype/logo-example1.svg";
import { ReactComponent as Indiwhite } from "assets/icons/logotype/logo-example2.svg";
import { ReactComponent as Indicream } from "assets/icons/logotype/logo-example3-1.svg";
import { ReactComponent as Indinova } from "assets/icons/logotype/logo-example3-2.svg";
import { ReactComponent as Indimelon } from "assets/icons/logotype/logo-example3-3.svg";

// OUR ICON
import { ReactComponent as Isologo } from "assets/icons/logotype/isologo.svg";

// MINIMUM SIZE
import { ReactComponent as Indiminimun } from "assets/icons/logotype/indi-minimum.svg";

// GRIDS SCALE
import { ReactComponent as Indiscale } from "assets/icons/logotype/indi-scale.svg";

// CONSTRUCTION
import { ReactComponent as Logoconstruction } from "assets/icons/logotype/logo-construction.svg";

// INCORRECT USES
import { ReactComponent as Incorrectuse1 } from "assets/icons/logotype/bad-example1.svg";
import { ReactComponent as Incorrectuse2 } from "assets/icons/logotype/bad-example2.svg";
import { ReactComponent as Incorrectuse3 } from "assets/icons/logotype/bad-example3.svg";
import { ReactComponent as Incorrectuse4 } from "assets/icons/logotype/bad-example4.svg";
import { ReactComponent as Incorrectuse5 } from "assets/icons/logotype/bad-example5.svg";
import { ReactComponent as Incorrectuse6 } from "assets/icons/logotype/bad-example6.svg";

const Logotype = () => {
  return (
    <Layout>
      <SEO
        title="Our Logotype & Guidelines - Indicius Brand Website"
        description="See our logotype and guidelines for how to use it properly"
      />
      <div className="logotype">
        <h1>Logotype</h1>
        <div className="logotype__header">
          <h3>Our logo</h3>
          <p>
            We are very proud of our logo, and we require that you follow these
            guidelines to ensure it always looks its best. Our logo is the
            combination of a simple and modern wordmark with the icon.
          </p>
          <Indilogo />
        </div>
        <div className="logotype__backgrounds">
          <p className="backgrounds__descriptions">
            The logo works best in Indiblue. The Indiblue logo should only be
            used on a white background, for any other background you should use
            a monochrome logo:
          </p>
          <div className="examples">
            <div className="box first">
              <Indiblack />
            </div>
            <div className="box bg--cream">
              <Indiblack />
            </div>
            <div className="box bg--supernova">
              <Indiblack />
            </div>
          </div>
          <p className="description">
            • The Stout logo should be used on light coloured backgrounds.
          </p>
          <div className="examples">
            <div className="box bg--indiblue">
              <Indiwhite />
            </div>
            <div className="box bg--midnight">
              <Indiwhite />
            </div>
            <div className="box bg--stout">
              <Indiwhite />
            </div>
          </div>
          <p className="description">
            • The White logo should be used on dark coloured backgrounds.
          </p>
          <div className="examples">
            <div className="box bg--indiblue">
              <Indicream />
            </div>
            <div className="box bg--midnight">
              <Indinova />
            </div>
            <div className="box bg--stout">
              <Indimelon />
            </div>
          </div>

          <p className="red description">
            • The logo shouldn’t be used in other colors, like Supernova,
            Watermelon, Midnight or Cream.
          </p>
        </div>
        <div className="our__icon">
          <h3>Our icon</h3>
          <p className="our__icon__paragraph">
            In cases when the brand has already been established we simply use
            the icon on its own. While the icon can exist without the wordmark,
            the wordmark should never exist without the icon. Same color rules
            apply:
          </p>

          <div className="icons-examples">
            <div className="col-large">
              <div className="cuadrado bg--white">
                <Isologo />
              </div>
            </div>
            <div className="col-small">
              <div className="cuadrado bg--white">
                <Isologo />
              </div>
              <div className="cuadrado bg--indiblue">
                <Isologo />
              </div>
              <div className="cuadrado bg--cream">
                <Isologo />
              </div>
              <div className="cuadrado bg--midnight">
                <Isologo />
              </div>
              <div className="cuadrado bg--supernova">
                <Isologo />
              </div>
              <div className="cuadrado bg--stout">
                <Isologo />
              </div>
            </div>
          </div>
        </div>
        <div>
          <h3>Minimum size</h3>
          <p>
            Establishing a minimum size ensures that the impact and legibility
            of the logo is not compromised in application.
            <br></br>
          </p>
          <p className="p">
            <span>
              <Indiminimun />
            </span>
            The logo or icon should never be smaller than
            <span> 16px height.</span>
          </p>
        </div>
        <div className="scale">
          <h3>Scale</h3>
          <p>
            There are no predetermined sizes for the Indicius Logo. Scale and
            proportion should be determined by the available space, aesthetics,
            function and visibility. Anyway, you should respect the 8px grid,
            adjusting the height to multiples of 8.
          </p>
          <div className="indi-scale">
            <Indiscale />
          </div>
        </div>
        <div className="logo__construction">
          <h3>Construction and exclusion zone</h3>
          <p className="example__explanation">
            The logo exclusion zone is equal to half the height of the dot of
            our icon. We carefully prepared this logo, if it’s necessary you can
            request access to our building files <span>here.</span>
          </p>
          <div>
            <Logoconstruction />
          </div>
        </div>
        <div className="incorrect__uses">
          <h3>Incorrect uses</h3>
          <div className="incorrect__examples">
            <div className="example">
              <Incorrectuse3 />
              <p className="example__explanation">
                • Don’t type out Indicius in any other fonts.
              </p>
            </div>
            <div className="example">
              <Incorrectuse1 />
              <p className="example__explanation">
                • Don’t stretch or manipulate the logo.
              </p>
            </div>
            <div className="example">
              <Incorrectuse2 />
              <p className="example__explanation">
                • Don’t pair the logo with marks that may be confused as logos.
              </p>
            </div>
            <div className="example">
              <Incorrectuse4 />
              <p className="example__explanation">
                • Don’t use any other colors for the logotype.
              </p>
            </div>
            <div className="example">
              <Incorrectuse5 />
              <p className="example__explanation">
                • Don’t use two colors at the same time.
              </p>
            </div>
            <div className="example">
              <Incorrectuse6 />
              <p className="example__explanation">
                • Don’t change the proportion of the isotype.
              </p>
            </div>
          </div>
        </div>
        <div>
          <h3>Download files</h3>
          <p>We have all our brand assets in an organized folder.</p>
          <br></br>
          <br></br>
          <a
            href="https://drive.google.com/drive/folders/1S7WopKtiBcybUl-UaGync22dinH-9One"
            target="_blank"
            rel="noreferrer"
            className="button button--indiblue">
            Get those files
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default Logotype;
