import React from "react";

// Icon
import { ReactComponent as Brandbook } from "assets/icons/design-system/Brandbook.svg";
// import { ReactComponent as BrandbookDesktop } from "assets/icons/menu/ds-icon-desktop.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/menu/icon-download.svg";

const BrandBookCard = () => {
  return (
    <div className="ds__card__container">
      <Brandbook className="ds-icon-mobile" />
      <Brandbook className="ds-icon-desktop" />
      <div className="ds__content">
        <p className="color--midnight ms-sm-3 me-sm-3 mb-md-2">
          Discover Indicius Brandbook
        </p>
        <a
          href="/"
          className="d-flex align-items-center justify-content-between color--indiblue"
        >
          Open Brandbook <DownloadIcon />
        </a>
      </div>
    </div>
  );
};

export default BrandBookCard;
