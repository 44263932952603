//BACKTOTOP BUTTON
import BackToTop from "components/backtotop";
import FooterNavigation from "components/footer-navigation";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__navigation">
        <FooterNavigation />
      </div>
      <div className="footer__update">
        <p>© Indicius {new Date().getFullYear()}</p>
        <p>Last updated 26 July 2021</p>
      </div>
      <BackToTop />
    </div>
  );
};

export default Footer;
