import Layout from "layouts/layout-primary";

// SEO
import SEO from "components/seo/";

// Grid examples
import Grid from "assets/icons/social-media/example-grid.png";
import Gridx2 from "assets/icons/social-media/example-grid@2x.png";
import Grid2 from "assets/icons/social-media/example-grid2.png";
import Grid2x2 from "assets/icons/social-media/example-grid2@2x.png";

// Illustration examples
import Illustration from "assets/icons/social-media/example-illustration.png";
import Illustrationx2 from "assets/icons/social-media/example-illustration@2x.png";
import Illustration2 from "assets/icons/social-media/example2-illustration.png";
import Illustration2x2 from "assets/icons/social-media/example2-illustration@2x.png";

// Case studies examples
import CaseStudies from "assets/icons/social-media/example-case-studies.png";
import CaseStudiesx2 from "assets/icons/social-media/example-case-studies@2x.png";
import CaseStudies2 from "assets/icons/social-media/example2-case-studies.png";
import CaseStudies2x2 from "assets/icons/social-media/example2-case-studies@2x.png";
import CaseStudies3 from "assets/icons/social-media/example3-case-studies.png";
import CaseStudies3x2 from "assets/icons/social-media/example3-case-studies@2x.png";
import DropShadow from "assets/icons/social-media/drop-shadow.png";
import DropShadowx2 from "assets/icons/social-media/drop-shadow@2x.png";

// Do - Don't examples
import DoDont from "assets/icons/social-media/example-do-dont.png";
import DoDontx2 from "assets/icons/social-media/example-do-dont@2x.png";
import DoDont2 from "assets/icons/social-media/example-do-dont2.png";
import DoDont2x2 from "assets/icons/social-media/example-do-dont2@2x.png";
import DoDont3 from "assets/icons/social-media/example-do-dont3.png";
import DoDont3x2 from "assets/icons/social-media/example-do-dont3@2x.png";

// Post examples
import Post from "assets/icons/social-media/example-post.png";
import Postx2 from "assets/icons/social-media/example-post@2x.png";
import Post2 from "assets/icons/social-media/example-post2.png";
import Post2x2 from "assets/icons/social-media/example-post2@2x.png";
import Post3 from "assets/icons/social-media/example-post3.png";
import Post3x2 from "assets/icons/social-media/example-post3@2x.png";
import Post4 from "assets/icons/social-media/example-post4.png";
import Post4x2 from "assets/icons/social-media/example-post4@2x.png";
import Post5 from "assets/icons/social-media/example-post5.png";
import Post5x2 from "assets/icons/social-media/example-post5@2x.png";
import Post6 from "assets/icons/social-media/example-post6.png";
import Post6x2 from "assets/icons/social-media/example-post6@2x.png";

const SocialMedia = () => {
  return (
    <Layout>
      <SEO
        title="Social Media Guidelines - Indicius Brand Website"
        description="Standards and guidelines for posting on Indicius social media"
      />
      <div className="social-media__container">
        <h1>Social Media</h1>
        <p>
          The purpose of this guide is for you to be able to understand it, and
          interact with it! Have fun!
        </p>

        <section className="social-media__typography">
          <h3>Typography</h3>
          <p className="small typography__explanation">
            Be very carefull with the use of each typography, there are two
            types: the DM SANS, which is actually our brand typeface, and
            ARCHIVO, which is our supportive font.
          </p>
          <div className="type-scale">
            <div className="row">
              <div className="col-12 col-sm-5">
                <h1 className="t-1">Body large</h1>
              </div>
              <div className="col-12 col-sm-2 align-items-center">
                <p>-2% spacing</p>
              </div>

              <div className="col-12 col-sm-2 align-items-center">
                <p>64px/80px</p>
              </div>

              <div className="col-12 col-sm-3 align-items-center">
                <p>DM Sans · Regular</p>
              </div>
            </div>
          </div>

          <div className="type-scale">
            <div className="row">
              <div className="col-12 col-sm-5">
                <h2 className="t-2">Body base</h2>
              </div>
              <div className="col-12 col-sm-2 d-flex align-items-center">
                <p>-2% spacing</p>
              </div>

              <div className="col-12 col-sm-2 d-flex align-items-center">
                <p>56px/72px</p>
              </div>

              <div className="col-12 col-sm-3 d-flex align-items-center">
                <p>DM Sans · Regular</p>
              </div>
            </div>
          </div>

          <div className="type-scale">
            <div className="row">
              <div className="col-12 col-sm-5">
                <h3 className="t-3">Detail</h3>
              </div>
              <div className="col-12 col-sm-2 d-flex align-items-center">
                <p>0% spacing</p>
              </div>

              <div className="col-12 col-sm-2 d-flex align-items-center">
                <p>40px/48px</p>
              </div>

              <div className="col-12 col-sm-3 d-flex align-items-center">
                <p>DM Sans · Regular</p>
              </div>
            </div>
          </div>

          <div className="type-scale">
            <div className="row">
              <div className="col-12 col-sm-5">
                <h1 className="t-4">Label</h1>
              </div>
              <div className="col-12 col-sm-2 d-flex align-items-center">
                <p>0% spacing</p>
              </div>

              <div className="col-12 col-sm-2 d-flex align-items-center">
                <p>40px/48px</p>
              </div>

              <div className="col-12 col-sm-3 d-flex align-items-center">
                <p>Dm Sans · Medium</p>
              </div>
            </div>
          </div>

          <div className="type-scale">
            <div className="row">
              <div className="col-12 col-sm-5">
                <p className="t-4">OVERLINE</p>
              </div>
              <div className="col-12 col-sm-2 d-flex align-items-center">
                <p>2% spacing</p>
              </div>

              <div className="col-12 col-sm-2 d-flex align-items-center">
                <p>40px/56px</p>
              </div>

              <div className="col-12 col-sm-3 d-flex align-items-center">
                <p>DM Sans · Bold</p>
              </div>
            </div>
          </div>

          <p className="typography__body">▸ Body & supportive styles</p>

          <div className="type-scale">
            <div className="row">
              <div className="col-12 col-sm-3 col-md-4">
                <h1 className="t-6">Heading XL-1</h1>
              </div>
              <div className="col-12 col-sm-2 d-flex align-items-center">
                <p>0% spacing</p>
              </div>

              <div className="col-12 col-sm-2 d-flex align-items-center">
                <p>56px/72px</p>
              </div>

              <div className="col-12 col-sm-3 d-flex align-items-center">
                <p>DM Sans · Archivo · Semibold</p>
              </div>
            </div>
          </div>

          <div className="type-scale">
            <div className="row">
              <div className="col-12 col-sm-3 col-md-4">
                <h1 className="t-7">Heading XL-2</h1>
              </div>
              <div className="col-12 col-sm-2 d-flex align-items-center">
                <p>0% spacing</p>
              </div>

              <div className="col-12 col-sm-2 d-flex align-items-center">
                <p>64px/80px</p>
              </div>

              <div className="col-12 col-sm-3 d-flex align-items-center">
                <p>DM Sans · Archivo · Semibold</p>
              </div>
            </div>
          </div>

          <div className="type-scale">
            <div className="row">
              <div className="col-12 col-sm-3 col-md-4">
                <p className="t-8">Heading XL-3</p>
              </div>
              <div className="col-12 col-sm-2 d-flex align-items-center">
                <p>0% spacing</p>
              </div>

              <div className="col-12 col-sm-2 d-flex align-items-center">
                <p>96px/112px</p>
              </div>

              <div className="col-12 col-sm-3 d-flex align-items-center">
                <p>DM Sans · Archivo · Medium</p>
              </div>
            </div>
          </div>

          <div className="type-scale">
            <div className="row">
              <div className="col-12 col-sm-3 col-md-4">
                <p className="t-9">Heading XL-4</p>
              </div>
              <div className="col-12 col-sm-2 d-flex align-items-center">
                <p>0% spacing</p>
              </div>

              <div className="col-12 col-sm-2 d-flex align-items-center">
                <p>120px/136px</p>
              </div>

              <div className="col-12 col-sm-3 d-flex align-items-center">
                <p>DM Sans · Archivo · Medium</p>
              </div>
            </div>
          </div>

          <div className="type-scale">
            <div className="row">
              <div className="col-12 col-sm-3 col-md-4">
                <p className="t-10">Heading XL-5</p>
              </div>
              <div className="col-12 col-sm-2 d-flex align-items-center">
                <p>0% spacing</p>
              </div>

              <div className="col-12 col-sm-2 d-flex align-items-center">
                <p>160px/180px</p>
              </div>

              <div className="col-12 col-sm-3 d-flex align-items-center">
                <p>DM Sans · Archivo · Medium</p>
              </div>
            </div>
          </div>
          <p className="headings color--stout small fw--400">
            ▸ Headings: The first heading XL-1, XL-2, XL-3 as far as three lines
            of text. The XL-4 for two text lines , and the last one, XL-5 for
            only 4 words.
          </p>
        </section>

        <section className="colors">
          <h3>Color</h3>
          <p>Check the colors and contrast of the background and typeface.</p>
          <div className="colors__examples">
            <div className="example bg--indiblue color--white fw--500">
              <p>
                White text <span className="color--stout">Stour text</span>
              </p>
            </div>
            <div className="example bg--midnight color--white fw--500">
              <p>
                White text{" "}
                <span className="color--indiblue">Indiblue text</span>
              </p>
            </div>
            <div className="example bg--supernova color--stout fw--500">
              <p>Stout text</p>
            </div>
            <div className="example bg--watermelon color--stout fw--500">
              <p>Stout text</p>
            </div>
            <div className="example bg--cream color--midnight fw--500">
              <p>Midnight text</p>
            </div>
            <div className="example border color--midnight fw--500">
              <p>
                Midnight text{" "}
                <span className="color--indiblue">Indiblue text</span>
              </p>
            </div>
            <div className="example bg--stout color--white fw--500">
              <p>
                White text{" "}
                <span className="color--indiblue">Indiblue text</span>
              </p>
            </div>
          </div>
        </section>

        <section className="grid">
          <h3>Grid</h3>
          <p className="small">¡Don't forget the safety margins!</p>
          <div className="grid__examples row">
            <div className="examples col-12 col-md-6">
              <img src={Grid} alt="Indicius" srcSet={Gridx2 + " 2x"} />
            </div>

            <div className="examples col-12 col-md-6">
              <img src={Grid2} alt="Indicius" srcSet={Grid2x2 + " 2x"} />
            </div>
          </div>
        </section>

        <section className="illustrations">
          <h3>Illustrations</h3>
          <p className="small">
            Always check that the line thickness is the same on all the illus
            you are using, for example if one is in 3px, all the illustrations
            must be done in 3px lines.
          </p>

          <div className="illustration__example row">
            <div className="example col-12 col-md-5">
              <img
                src={Illustration2}
                alt="Indicius"
                srcSet={Illustration2x2 + " 2x"}
              />
              <p className="dont">
                • Don't mix the line thickness on illustrations.
              </p>
            </div>

            <div className="example col-12 col-md-5">
              <img
                src={Illustration}
                alt="Indicius"
                srcSet={Illustrationx2 + " 2x"}
              />
              <p className="do">
                • The illustrations must be in the same line thickness and
                always in an integer.
              </p>
            </div>
          </div>
        </section>

        <section className="case-studies">
          <h3>Case study</h3>
          <p className="small">
            Emphasize the process, visual branding, design sprint and marketing
            site, everything that is considered necessary to show it in a
            professional way.
          </p>
          <div className="case-studies__examples">
            <img
              src={CaseStudies}
              alt="Indicius"
              srcSet={CaseStudiesx2 + " 2x"}
            />
            <img
              src={CaseStudies2}
              alt="Indicius"
              srcSet={CaseStudies2x2 + " 2x"}
            />
            <img
              src={CaseStudies3}
              alt="Indicius"
              srcSet={CaseStudies3x2 + " 2x"}
            />
          </div>

          <div className="covers__description">
            <p>
              The covers should show a composition of the project pieces with
              the most visual impact, using backgrounds and some elements of
              Indi Branding.
            </p>
            <br />
            <p>
              If the project had animations, we should show them in the case
              study.
            </p>
            <br />
            <div className="examples__description">
              <p className="description">
                • There is a preset for corner radius, it's 8px.
              </p>
              <p className="description">
                • Also, we already have a shadow style.
              </p>
              <img
                src={DropShadow}
                alt="Indicius"
                srcSet={DropShadowx2 + " 2x"}
              />
            </div>
          </div>
        </section>

        <div className="do-dont__container">
          <h3>Do's and don'ts</h3>
          <p className="small mb-4">Common mistakes you need to avoid.</p>
          <div className="do-dont__examples row">
            <div className="example dont col-12 col-md-5">
              <img src={DoDont} alt="Indicius" srcSet={DoDontx2 + " 2x"} />
              <p className="dont small">
                • Do not use two different colors in one line.
              </p>
            </div>

            <div className="example do col-12 col-md-5">
              <img src={DoDont2} alt="Indicius" srcSet={DoDont2x2 + " 2x"} />
              <p className="do">• Correct use of styles.</p>
            </div>

            <div className="example dont col-12 col-md-5">
              <img src={DoDont3} alt="Indicius" srcSet={DoDont3x2 + " 2x"} />
              <p className="dont mb-4">
                • Do not use two different text styles in a phrase.
              </p>
            </div>
          </div>
        </div>

        <section className="post">
          <h3>Example posts</h3>
          <p className="small">
            Here we show you some post examples taking into consideration has
            been explained so far, be free to modify whatever you want but
            always bear the guideline in mind!
          </p>
          <div className="post__examples row">
            <div className="example col-12 col-md-4">
              <img src={Post} alt="Indicius" srcSet={Postx2 + " 2x"} />
            </div>
            <div className="example col-12 col-md-4">
              <img src={Post3} alt="Indicius" srcSet={Post3x2 + " 2x"} />
            </div>
            <div className="example col-12 col-md-4">
              <img src={Post2} alt="Indicius" srcSet={Post2x2 + " 2x"} />
            </div>
            <div className="example col-12 col-md-4">
              <img src={Post4} alt="Indicius" srcSet={Post4x2 + " 2x"} />
            </div>
            <div className="example col-12 col-md-4">
              <img src={Post5} alt="Indicius" srcSet={Post5x2 + " 2x"} />
            </div>
            <div className="example col-12 col-md-4">
              <img src={Post6} alt="Indicius" srcSet={Post6x2 + " 2x"} />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default SocialMedia;
