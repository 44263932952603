import Layout from "../../layouts/layout-primary";

// Components
import SEO from "components/seo/";

const DoDont = () => {
  return (
    <Layout>
      <SEO
        title="Do’s & Don’ts of On-Brand Copy - Indicius Brand Website"
        description="Some tips and guidelines for writing copy that expresses our values"
      />
      <div className="do-donts">
        <h1 className="content__title">Do’s and dont's</h1>

        <p>Guidelines to follow when writing copy for Indicius.</p>

        <div className="do-donts__table">
          <div className="row">
            <div className="col-12 col-lg-6">
              <h4 className="table__title table__title--indiblue">DO</h4>

              <p className="item item--3">Focus on the benefit.</p>

              <p className="item item--2">
                Throw in some humor (when appropriate).
              </p>

              <p className="item item--3">
                Do focus on how we’re remote-friendly and pros at using remote
                collaboration tools.
              </p>

              <p className="item item--3">Be authentic.</p>
            </div>

            <div className="col-12 col-lg-6">
              <h4 className="table__title table__title--dont">DON'T</h4>
              <p className="item item--3">
                Don’t get too caught up in talking about what we do. How can we
                help the reader? Why should they care?
              </p>

              <p className="item item--2">
                Don’t be too silly. We’re playful, but we’re professionals to
                the core.
              </p>

              <p className="item item--3">
                Don’t put too much focus on the fact that we’re in Buenos Aires.
              </p>

              <p className="item item--3">
                Don’t get too caught up in what we should say. What do you want
                to say? What does our audience need to hear?
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DoDont;
