import React from 'react'

// Libraries
import Helmet from 'react-helmet'

// Images

const SEO = ({ title, description, image }) => {
  const defaults = {
    title: 'Brand Guide - Indicius',
    description: "We're awesome. Here's a guide of how we do it."
  }

  const seo = {
    title: title ? title : defaults.title,
    description: description ? description : defaults.description
  }

  return (
    <Helmet title={seo.title} description={seo.description}>
      <link rel="icon" type="image/png" href={process.env.PUBLIC_URL + '/favicon.png'}></link>
      <meta property="og:image" content={process.env.PUBLIC_URL + '/open-graph.png'} />
    </Helmet>
  )
}

export default SEO
