//Layout
import Layout from "layouts/layout-primary";

// Components
import SEO from "components/seo/";

//Icons
import { ReactComponent as Color } from "assets/icons/principles/icon-color.svg";
import { ReactComponent as Motion } from "assets/icons/principles/icon-motion.svg";
import { ReactComponent as Illustration } from "assets/icons/principles/icon-illustration.svg";
import { ReactComponent as Accessibility } from "assets/icons/principles/icon-accessibility.svg";

const Principles = () => {
  return (
    <Layout>
      <SEO
        title="Principles of Our Brand - Indicius Brand Website"
        description="How we use our visual branding elements to convey our values"
      />
      <div className="principles">
        <h1 className="content__title">Principles</h1>
        <p className="principles__subtitle detail">
          <strong>Designs that humanize</strong>
        </p>
        <br />
        <p>
          {" "}
          Every element of our visual brand supports our core values, mission,
          and vision for Indicius. The key concepts we work to express this way
          are:
        </p>
        <ul className="bulleted-list list">
          <li>We build together</li>
          <li>We’re always in motion</li>
          <li>We’re humans helping humans meet their goals</li>
        </ul>
        <p>
          So, how do we express these concepts using design? Well, there are a
          few ways, which you’ll see more in detail as you go through the guide.
        </p>
        <div className="principles__concepts">
          <div className="concept">
            <Color />
            <p className="principles__title detail">
              <strong>color</strong>
            </p>
            <p>
              Our color palette combines a modern, fresh look with a sense of
              fun and playfulness, showing that we are innovative and not afraid
              to get creative and try new things.
            </p>
          </div>
          <div className="concept">
            <Motion />
            <p className="principles__title detail">
              <strong>motion</strong>
            </p>
            <p>
              Whenever possible we like to include animated elements in our
              designs and illustrations to give a sense of constantly moving
              forward and making changes for the better.
            </p>
          </div>
          <div className="concept">
            <Illustration />
            <p className="principles__title detail">
              <strong>illustration</strong>
            </p>
            <p>
              There are common elements in our illustrations: Lack of outlines,
              a sense of motion, hands, and colorful, flexible characterrs
              interacting.These elements showcase our values as a company that
              values creativity and collaboration.
            </p>
          </div>
          <div className="concept">
            <Accessibility />
            <p className="principles__title detail">
              <strong>accessibility</strong>
            </p>
            <p>
              We can’t claim to be humans helping humans achieve their goals if
              that doesn’t mean all humans. We hold ourselves to a high standard
              of accessibility in our designs while ensuring compliance with the
              Web Content Accessibility Guidelines.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Principles;
