// Libraries
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

// Styles
import "assets/styles/main.scss";

// Pages 👇🏻
import Home from "pages/index.js";
import BrandVision from "pages/brand-vision";
import CaseStudy from "pages/case-study";
import Grammar from "pages/grammar";
import WordList from "pages/word-list";
import Icons from "pages/icons";
import DoDont from "pages/do-don't";
import Typography from "pages/typography";
import Illustration from "pages/illustration";
import Photography from "pages/photography";
import Logotype from "pages/logotype";
import Color from "pages/color";
import VoiceChart from "pages/voice-chart";
import Intro from "pages/get-started/introduction";
import Principles from "pages/get-started/principles";
import Motion from "pages/motion";
import SonicBranding from "pages/sonic-branding";
import ThreeDElements from "pages/3d-elements";
import SocialMedia from "pages/social-media";
import DesignSystem from "pages/design-system";

ReactDOM.render(
  <Router>
    <Route exact path="/" component={Intro} />
    <Route exact path="/principles" component={Principles} />
    <Route exact path="/introduction" component={Home} />
    <Route path="/brand-vision" component={BrandVision} />
    <Route path="/voice-chart" component={VoiceChart} />
    <Route path="/dos-and-dont" component={DoDont} />
    <Route path="/case-studies" component={CaseStudy} />
    <Route path="/grammar-and-mechanics" component={Grammar} />
    <Route path="/word-list" component={WordList} />
    <Route path="/icons" component={Icons} />
    <Route path="/typography" component={Typography} />
    <Route path="/illustration" component={Illustration} />
    <Route path="/photography" component={Photography} />
    <Route path="/logotype" component={Logotype} />
    <Route path="/color" component={Color} />
    <Route path="/motion" component={Motion} />
    <Route path="/sonic-branding" component={SonicBranding} />
    <Route path="/3d-elements" component={ThreeDElements} />
    <Route path="/social-media" component={SocialMedia} />
    <Route path="/design-system" component={DesignSystem} />
  </Router>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
