import React from "react";
import Layout from "../../layouts/layout-secondary";

// Components
import SEO from "components/seo/";

const DesignSystem = () => {
  return (
    <Layout>
      <SEO title="Design System - Indicius Brand Website" description="" />
      <div>
        <h2 className="title-d-system">Design System</h2>
        <br />
        <p className="mb-5 mt-2">
          Our Design System is not yet available to the public. Our team is
          working towards making it accessible to the community. If you have any
          questions or suggestions, please contact us at contact@indicius.com
        </p>
        <br />
        <a href="/" className="button button--indiblue">
          Back to Brandbook
        </a>
      </div>
    </Layout>
  );
};

export default DesignSystem;
