import Layout from "layouts/layout-primary";

// Components
import SEO from "components/seo/";

const voiceChart = () => {
  return (
    <Layout>
      <SEO
        title="Voice Chart for On-Brand Copy - Indicius Brand Website"
        description="How to write copy that expresses our values at Indicius"
      />
      <div className="voice">
        <h1>Voice chart: Translating key concepts into content</h1>

        <p className="voice-introduction">
          This chart looks at different basic elements of messaging and defines
          how each of our three key concepts should look to have a consistent
          voice.
        </p>

        <div className="voice__cuadro row">
          <div className="cuadro__title">
            <h4>human</h4>
            <h4>collaboration</h4>
            <h4>in motion</h4>
          </div>

          <div className="col col-12 col-sm-3 start-grilla">
            <p>
              <span> Concepts </span>- ideas to be emphasized throughout the
              experience that reflect the role we want to have in users’ lives.{" "}
            </p>
          </div>

          <div className="col col-12 col-sm-3">
            <h4 className="title--indiblue">human</h4>
            <p className="paragraph--grilla">
              Approachable, likeable. We focus on human needs in our design, so
              we should write like humans. We’re good listeners.{" "}
            </p>
          </div>

          <div className="col col-12 col-sm-3">
            <h4 className="title--indiblue">colaboration</h4>
            <p className="paragraph--grilla">
              The best design happens when we work together as a team, and
              together with our clients. Family spirit.
            </p>
          </div>

          <div className="col col-12 col-sm-3 motion">
            <h4 className="title--indiblue">in motion</h4>
            <p className="paragraph--grilla">
              Innovation. We’re living on the edge, trying the next best thing.
              We don’t stay comfortable.
            </p>
          </div>
        </div>
        <div className="voice__cuadro row">
          <div className="col col-12 col-sm-3 start-grilla">
            <p>
              <span>Vocabulary</span> - what kinds of words embody this
              principle?
            </p>
          </div>

          <div className="col col-12 col-sm-3">
            <h4 className="title--indiblue">human</h4>
            <p className="paragraph--grilla">
              Use the most common words, except when necessary for a technical
              concept. Ask yourself—is there a more common word that could
              replace this?
            </p>
          </div>

          <div className="col col-12 col-sm-3">
            <h4 className="title--indiblue">colaboration</h4>
            <p className="paragraph--grilla">
              Common words to bring people in. We don’t want to exclude anyone
              with big words.
            </p>
          </div>

          <div className="col col-12 col-sm-3 motion">
            <h4 className="title--indiblue">in motion</h4>
            <p className="paragraph--grilla">
              Up-to-date words that show we know what we’re talking about.
            </p>
          </div>
        </div>

        <div className="voice__cuadro row">
          <div className="col col-12 col-sm-3 start-grilla">
            <p>
              <span>Verbosity</span> - do we prefer longer or shorter copy?
            </p>
          </div>
          <div className="col col-12 col-sm-3 ">
            <h4 className="title--indiblue">human</h4>
            <p className="paragraph--grilla">
              Enough words to have unambiguous information and express humanity,
              but not too many to feel like we’re inauthentic.
            </p>
          </div>
          <div className="col col-12 col-sm-3">
            <h4 className="title--indiblue">collaboration</h4>
            <p className="paragraph--grilla">
              Enough words to bring our audience in and not make them feel
              excluded.
            </p>
          </div>
          <div className="col col-12 col-sm-3 motion">
            <h4 className="title--indiblue">in motion</h4>
            <p className="paragraph--grilla">
              Short—just enough words to get us where we’re going.
            </p>
          </div>
        </div>
        <div className="voice__cuadro row">
          <div className="col col-12 col-sm-3 start-grilla">
            <p>
              <span>Grammar</span> - what grammar rules embody this principle?
            </p>
          </div>

          <div className="col col-12 col-sm-3 ">
            <h4 className="title--indiblue">human</h4>
            <p className="paragraph--grilla">
              Simple sentences or short phrases — humans don’t always speak in
              perfectly formed sentences.
            </p>
          </div>

          <div className="col col-12 col-sm-3">
            <h4 className="title--indiblue">collabortion</h4>
            <p className="paragraph--grilla">
              Simple sentences or short phrases.
            </p>
          </div>

          <div className="col col-12 col-sm-3 motion">
            <h4 className="title--indiblue">in motion</h4>
            <p className="paragraph--grilla">
              Simple sentences or short phrases.
            </p>
          </div>
        </div>
        <div className="voice__cuadro row">
          <div className="col col-12 col-sm-3 start-grilla">
            <p>
              <span>Punctuation </span> - what punctuation supports this
              principle?
            </p>
          </div>

          <div className="col col-12 col-sm-3">
            <h4 className="title--indiblue">human</h4>
            <p className="paragraph--grilla">
              Use periods, commas. Avoid exclamation points.
            </p>
          </div>

          <div className="col col-12 col-sm-3">
            <h4 className="title--indiblue">collaboration</h4>
            <p className="paragraph--grilla">
              Avoid semicolons, dashes, parenthetical remarks.
            </p>
          </div>

          <div className="col col-12 col-sm-3">
            <h4 className="title--indiblue">in motion</h4>
            <p className="paragraph--grilla">
              Use periods, commas. Avoid question marks. Avoid terminal
              punctuation for instructions.
            </p>
          </div>
        </div>
        <div className="tone">
          <h3>Tone</h3>
          <p>
            Adjust the tone of the copy based on the situation in front of you.
          </p>
          <p className="tone-ex">For example:</p>
          <p className="tone-paragraph">
            • In sales decks, or other marketing materials, we’ll have the same
            Indicius voice, but we might take on a more persuasive tone.
          </p>
          <p className="tone-paragraph">
            • If we’re teaching our community how to use a design tool in a
            social media post, we’ll take on a more authoritative, educational
            tone.{" "}
          </p>
          <p className="tone-paragraph">
            • And in onboarding materials for getting our clients up to speed on
            how we work, we might take on a more helpful, guiding tone.{" "}
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default voiceChart;
