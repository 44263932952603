// React
import React from "react";

// Layout
import Layout from "../../layouts/layout-primary";

// SEO
import SEO from "components/seo/";

//INCORRECT USES
import { ReactComponent as IncorrectBold } from "assets/icons/typography/bold-example.svg";
import { ReactComponent as OtherExample } from "assets/icons/typography/other-example.svg";
import { ReactComponent as KerningExample } from "assets/icons/typography/kerning-example.svg";
import { ReactComponent as SeparationExample } from "assets/icons/typography/separation-example.svg";
import { ReactComponent as TextInColor } from "assets/icons/typography/text-in-color.svg";
import { ReactComponent as AllCaps } from "assets/icons/typography/all-caps.svg";

const Typography = () => {
  return (
    <Layout>
      <SEO
        title="Our Typography & Guidelines - Indicius Brand Website"
        description="See the fonts we use at Indicius and where to use each one"
      />
      <div className="typography">
        <h1 className="typography__title">Typography</h1>
        <h3 className="typography__intro">Intro</h3>
        <div className="typography__dm">
          <h1 className="dm__title">
            This is DM Sans.<br></br> Our brand typeface.
          </h1>

          <p className="dm">
            DM Sans is the primary brand typeface for Indicius.
          </p>
          <a
            href="https://fonts.google.com"
            target="_blank"
            rel="noopener noreferrer"
            className=" button button--indiblue"
          >
            Download DM Sans
          </a>
        </div>
        <div className="typography__archivo">
          <h3>Archivo is our supporting font</h3>
          <p className="archivo detail">
            Archivo is the secondary typeface for our brand that we use for.....
          </p>
          <div>
            <a
              href="https://fonts.google.com"
              target="_blank"
              rel="noopener noreferrer"
              className="button button--indiblue"
            >
              Download Archivo
            </a>
          </div>
        </div>

        <h3>Type Scale</h3>
        <p className="type__paragraph">
          In Indicius we will often use an 8-point grid for spacing and sizing
          with a 4-point baseline grid for typography.
          <br />
          The type baselines will sit on on the 4-point grid. In order to
          achieve this, the focus here is really more on the line-heights than
          the exact font sizes. By positioning the baseline of each line of text
          into evenly-spaced lines, you can easily bring all of your UI elements
          into a harmonious vertical rhythm. This pairing keeps the math simple
          and clean while providing sufficient options to fit a variety of text
          styles.
        </p>

        <div className="type-scale">
          <div className="row">
            <div className="col-12 col-sm-5">
              <h1 className="t-1 archivo">Heading One</h1>
            </div>
            <div className="col-12 col-sm-2 d-flex align-items-center">
              <p>-2% spacing</p>
            </div>

            <div className="col-12 col-sm-2 d-flex align-items-center">
              <p>44px/52px</p>
            </div>

            <div className="col-12 col-sm-3 d-flex align-items-center">
              <p>Archivo · Medium</p>
            </div>
          </div>
        </div>

        <div className="type-scale">
          <div className="row">
            <div className="col-12 col-sm-5">
              <h2 className="t-2 archivo">Heading Two</h2>
            </div>

            <div className="col-12 col-sm-2 d-flex align-items-center">
              <p>-1% spacing</p>
            </div>
            <div className="col-12 col-sm-2 d-flex align-items-center">
              <p>32px/40px</p>
            </div>
            <div className="col-12 col-sm-3 d-flex align-items-center">
              <p>Archivo · Medium</p>
            </div>
          </div>
        </div>
        <div className="type-scale">
          <div className="row">
            <div className="col-12 col-sm-5">
              <h3 className="t-3">Heading Three</h3>
            </div>
            <div className="col-12 col-sm-2 d-flex align-items-center">
              <p>0% spacing</p>
            </div>
            <div className="col-12 col-sm-2 d-flex align-items-center">
              <p>24px/28px</p>
            </div>
            <div className="col-12 col-sm-3 d-flex align-items-center">
              <p>DM Sans · Regular</p>
            </div>
          </div>
        </div>
        <div className="type-scale">
          <div className="row">
            <div className="col-12 col-sm-5">
              <h4 className="t-4">Heading Four</h4>
            </div>

            <div className="col-12 col-sm-2 d-flex align-items-center">
              <p>1% spacing</p>
            </div>
            <div className="col-12 col-sm-2 d-flex align-items-center">
              <p>20px/24px</p>
            </div>
            <div className="col-12 col-sm-3 d-flex align-items-center">
              <p>DM Sans · Regular</p>
            </div>
          </div>
        </div>
        <div className="type-scale">
          <div className="row">
            <div className="col-12 col-sm-5">
              <p className="t-5">Body</p>
            </div>

            <div className="col-12 col-sm-2 d-flex align-items-center">
              <p>2% spacing</p>
            </div>
            <div className="col-12 col-sm-2 d-flex align-items-center">
              <p>16px/24px</p>
            </div>
            <div className="col-12 col-sm-3 d-flex align-items-center">
              <p>DM Sans · Regular</p>
            </div>
          </div>
        </div>
        <div className="type-scale">
          <div className="row">
            <div className="col-12 col-sm-5">
              <p className="t-6">Body-small</p>
            </div>

            <div className="col-12 col-sm-2 d-flex align-items-center">
              <p>2% spacing</p>
            </div>
            <div className="col-12 col-sm-2 d-flex align-items-center">
              <p>12px/20px</p>
            </div>
            <div className="col-12 col-sm-3 d-flex align-items-center">
              <p>DM Sans · Regular</p>
            </div>
          </div>
        </div>

        <div className="paragraph__body">
          <h3>Paragraphs</h3>
          <p>
            The default body text is 16px. For optimum reading length, the
            paragraph width is capped at 640px.
          </p>
          <p className="example">Example:</p>
          <div className="sub__section">
            <h3>Sub-section title</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <p className="sub__small__paragraph">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollilaborum.
            </p>
          </div>
        </div>

        <div className="incorrect__uses">
          <h3 className="incorrect__title">Incorrect uses</h3>
          <div className="incorrect__examples">
            <div className="example">
              <IncorrectBold />
              <p className="example__explanation">
                • Don’t make different levels of hierarchy same weight.
              </p>
            </div>
            <div className="example">
              <OtherExample />
              <p className="example__explanation">
                • Don’t manipulate kerning or tracking.
              </p>
            </div>
            <div className="example">
              <KerningExample />
              <p className="example__explanation">
                • Don’t use any other fonts.
              </p>
            </div>
            <div className="example">
              <SeparationExample />
              <p className="example__explanation">
                • Don’t separate chunks of text.
              </p>
            </div>
            <div className="example">
              <TextInColor />
              <p className="example__explanation">
                • Don’t use text in color, specially if it doesn’t pass
                accesibility tests.
              </p>
            </div>
            <div className="example">
              <AllCaps />
              <p className="example__explanation">• Don’t use all caps.</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Typography;
