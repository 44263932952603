import Layout from "layouts/layout-primary";

// 3d People
import persona from "assets/icons/3d-elements/3d-image.png";
import personax2 from "assets/icons/3d-elements/3d-image@2x.png";
import persona2 from "assets/icons/3d-elements/3d-image2.png";
import persona2x2 from "assets/icons/3d-elements/3d-image2@2x.png";
import persona3 from "assets/icons/3d-elements/3d-image3.png";
import persona3x2 from "assets/icons/3d-elements/3d-image3@2x.png";
import persona4 from "assets/icons/3d-elements/3d-image4.png";
import persona4x2 from "assets/icons/3d-elements/3d-image4@2x.png";
import persona5 from "assets/icons/3d-elements/3d-image5.png";
import persona5x2 from "assets/icons/3d-elements/3d-image5@2x.png";
import persona6 from "assets/icons/3d-elements/3d-image6.png";
import persona6x2 from "assets/icons/3d-elements/3d-image6@2x.png";
import persona7 from "assets/icons/3d-elements/3d-image7.png";
import persona7x2 from "assets/icons/3d-elements/3d-image7@2x.png";

// 3D Objects
import object from "assets/icons/3d-elements/3d-object.png";
import objectx2 from "assets/icons/3d-elements/3d-object@2x.png";
import object2 from "assets/icons/3d-elements/3d-object2.png";
import object2x2 from "assets/icons/3d-elements/3d-object2@2x.png";
import object3 from "assets/icons/3d-elements/3d-object3.png";
import object3x2 from "assets/icons/3d-elements/3d-object3@2x.png";
import object4 from "assets/icons/3d-elements/3d-object4.png";
import object4x2 from "assets/icons/3d-elements/3d-object4@2x.png";
import object5 from "assets/icons/3d-elements/3d-object5.png";
import object5x2 from "assets/icons/3d-elements/3d-object5@2x.png";
import object6 from "assets/icons/3d-elements/3d-object6.png";
import object6x2 from "assets/icons/3d-elements/3d-object6@2x.png";
import object7 from "assets/icons/3d-elements/3d-object7.png";
import object7x2 from "assets/icons/3d-elements/3d-object7@2x.png";
import object8 from "assets/icons/3d-elements/3d-object8.png";
import object8x2 from "assets/icons/3d-elements/3d-object8@2x.png";
import object9 from "assets/icons/3d-elements/3d-object9.png";
import object9x2 from "assets/icons/3d-elements/3d-object9@2x.png";

// SEO
import SEO from "components/seo/";

const ThreeDElements = () => {
  return (
    <Layout>
      <SEO
        title="3D Brand Elements - Indicius Brand Website"
        description="See our Indicius-branded 3D illustrations and elements"
      />
      <div className="three-dimensions__container">
        <h1>3D Elements</h1>
        <p className="three-dimensions__paragraph">
          The purpose of this guide is to standardize our illustrations. Use the
          guide as a reference when you are creating new illustrations or icons
          in 3D.
        </p>

        <h3 className="sub-title">People/Characters</h3>
        <div className="people--icons">
          <div className="people">
            <img src={persona} alt="Indicius" srcSet={personax2 + " 2x"} />
            <img src={persona2} alt="Indicius" srcSet={persona2x2 + " 2x"} />
            <img src={persona3} alt="Indicius" srcSet={persona3x2 + " 2x"} />
            <img src={persona4} alt="Indicius" srcSet={persona4x2 + " 2x"} />
            <img src={persona5} alt="Indicius" srcSet={persona5x2 + " 2x"} />
            <img src={persona6} alt="Indicius" srcSet={persona6x2 + " 2x"} />
            <img src={persona7} alt="Indicius" srcSet={persona7x2 + " 2x"} />
          </div>
        </div>

        <div className="three-dimensions__objects">
          <h3>Objects</h3>
          <div className="object--icons">
            <div className="object">
              <img src={object} alt="Indicius" srcSet={objectx2 + " 2x"} />
              <img src={object2} alt="Indicius" srcSet={object2x2 + " 2x"} />
              <img src={object3} alt="Indicius" srcSet={object3x2 + " 2x"} />
              <img src={object4} alt="Indicius" srcSet={object4x2 + " 2x"} />
              <img src={object5} alt="Indicius" srcSet={object5x2 + " 2x"} />
              <img src={object6} alt="Indicius" srcSet={object6x2 + " 2x"} />
              <img src={object7} alt="Indicius" srcSet={object7x2 + " 2x"} />
              <img src={object8} alt="Indicius" srcSet={object8x2 + " 2x"} />
              <img src={object9} alt="Indicius" srcSet={object9x2 + " 2x"} />
            </div>
          </div>
        </div>

        <div className="three-dimensions__downloads">
          <h3>Download files</h3>
          <p className="downloads__paragraph">
            We have all our 3D elements in an organized folder.
          </p>
          <a
            href="/"
            rel="noreferrer"
            target="_blank"
            className="button button--indiblue">
            Get those files
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default ThreeDElements;
