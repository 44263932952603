import Layout from "../../layouts/layout-primary";

// Components
import SEO from "components/seo/";

//ILUSTRATIONS
import { ReactComponent as IndiCol1 } from "assets/icons/illustration/icons-columna1.svg";
import { ReactComponent as IndiCol2 } from "assets/icons/illustration/icons-columna2.svg";

//LINE EXAMPLES
import { ReactComponent as Pencil } from "assets/icons/illustration/icon1.svg";
import { ReactComponent as Flowerpot } from "assets/icons/illustration/icon2.svg";
import { ReactComponent as Plane } from "assets/icons/illustration/icon3.svg";
import { ReactComponent as Pentip } from "assets/icons/illustration/icon4.svg";
import { ReactComponent as Email } from "assets/icons/illustration/icon5.svg";

//OUTLINE EXAMPLE
import { ReactComponent as OutlinePencil } from "assets/icons/illustration/outline-icon1.svg";
import { ReactComponent as OutlineFlowerpot } from "assets/icons/illustration/outline-icon2.svg";
import { ReactComponent as OutlinePlane } from "assets/icons/illustration/outline-icon3.svg";
import { ReactComponent as OutlinePentip } from "assets/icons/illustration/outline-icon4.svg";
import { ReactComponent as OutlineEmail } from "assets/icons/illustration/outline-icon5.svg";

//HEAD CONSTRUCTION
import { ReactComponent as Face1 } from "assets/icons/illustration/face1.svg";
import { ReactComponent as Face2 } from "assets/icons/illustration/face2.svg";
import { ReactComponent as Face3 } from "assets/icons/illustration/face3.svg";

//PEOPLE/CHARACTERS
import { ReactComponent as CharacterImage } from "assets/icons/illustration/character-image.svg";
import { ReactComponent as HeadBase } from "assets/icons/illustration/head-base.svg";
import { ReactComponent as FaceStyle } from "assets/icons/illustration/face-style.svg";
import { ReactComponent as HairStyle } from "assets/icons/illustration/hair-style.svg";

//BODY STRUCTURE
import { ReactComponent as BodyStructure1 } from "assets/icons/illustration/body-structure1.svg";
import { ReactComponent as BodyStructure2 } from "assets/icons/illustration/body-structure2.svg";
import { ReactComponent as BodyStructure3 } from "assets/icons/illustration/body-structure3.svg";
import { ReactComponent as BodyStructure4 } from "assets/icons/illustration/body-structure4.svg";
import { ReactComponent as BodyStructure5 } from "assets/icons/illustration/body-structure5.svg";

//ARMS AND HANDS
import { ReactComponent as Hands1 } from "assets/icons/illustration/hands1.svg";
import { ReactComponent as Hands2 } from "assets/icons/illustration/hands2.svg";
import { ReactComponent as Hands3 } from "assets/icons/illustration/hands3.svg";
import { ReactComponent as Hands4 } from "assets/icons/illustration/hands4.svg";

//LEGS
import { ReactComponent as Legs1 } from "assets/icons/illustration/legs1.svg";
import { ReactComponent as Legs2 } from "assets/icons/illustration/legs2.svg";
import { ReactComponent as Legs3 } from "assets/icons/illustration/legs3.svg";
import { ReactComponent as Legs4 } from "assets/icons/illustration/legs4.svg";

//OBJECTS
import { ReactComponent as Objects1 } from "assets/icons/illustration/objects1.svg";
import { ReactComponent as Objects2 } from "assets/icons/illustration/objects2.svg";

//COLORS EXAMPLES
import { ReactComponent as Color1 } from "assets/icons/illustration/colors1.svg";
import { ReactComponent as Color2 } from "assets/icons/illustration/colors2.svg";
import { ReactComponent as Color3 } from "assets/icons/illustration/colors3.svg";
import { ReactComponent as Color4 } from "assets/icons/illustration/colors4.svg";
import { ReactComponent as Color5 } from "assets/icons/illustration/colors5.svg";

const Illustatrion = () => {
  return (
    <Layout>
      <SEO
        title="Branded Illustrations & Guidelines - Indicius Brand Website"
        description="How to create on-brand illustrations for Indicius content"
      />
      <div className="illustration">
        <h1 className="content__title">Illustration</h1>
        <p className="illus__subtitle">
          The purpose of this guide is to standardize our illustrations. Use the
          guide as a reference when you are creating new illustrations or icons.
        </p>
        {/* COLORS START */}
        <div>
          <h3 className="illus__color">Color</h3>
          <p className="illus__color--subtitle">
            These are the colors used in Indicius' illustrations.
          </p>
          <div className="color__container">
            <p>
              #082C4E <span>Midnight</span>
            </p>
          </div>
          <p className="illustration__desc illustration__desc--grey">
            ︎▸ Dark Midnight Blue is always used for lines and characters' feet.
          </p>
          <div className="nova color__container">
            <p>
              #FFCC00 <span>Supernova</span>
            </p>
          </div>
          <div className="indi color__container">
            <p>
              #3098F3 <span>Indiblue</span>
            </p>
          </div>
          <div className="melon color__container">
            <p>
              #FE6571 <span>Watermelon</span>
            </p>
          </div>
          <p className="illustration__desc">
            • The colors Supernova, Indiblue, and watermelon are used for
            characters' bodies, objects, and backgrounds.
          </p>
          <div className="cream color__container">
            <p>
              #FE6571 <span>Cream</span>
            </p>
          </div>
          <p className="illustration__desc">
            • The color cream can be used for backgrounds and objects.
          </p>
        </div>
        {/* COLORS END */}
        {/* LINE SHAPES START */}
        <h3>Lines & shapes</h3>
        <p>
          Illustrations, objects, and people are made up of basic lines and
          shapes. Lines are always Midnight Blue and sized proportionally to the
          illustration. The same thickness should be maintained for all the
          lines in a single composition. For an illustration that is
          approximately 1440 x 772 px, a 2px line should be used.
          <br /> Finally, they should be modulated in order to achieve the
          appearance of a manual stroke.
        </p>
        <div className="indi__icons">
          <div className="col-12 col-sm-6 svg-first">
            <IndiCol1 />
          </div>
          <div className="col-12 col-sm-6 svg-last">
            <IndiCol2 />
          </div>
        </div>
        <h4 className="examples__title">Line’s use examples</h4>
        <div className="line__examples">
          <Pencil />
          <Flowerpot />
          <Plane />
          <Pentip />
          <Email />
        </div>
        <p className="line">
          • The use of lines is important as it defines the shape of the
          elements depicted.
        </p>
        <br />
        <div className="line__examples red">
          <OutlinePencil />
          <OutlineFlowerpot />
          <OutlinePlane />
          <OutlinePentip />
          <OutlineEmail />
        </div>
        <p className="line outline">
          • Lines should not be used to outline the shapes.
        </p>
        <div className="people__character">
          <h3>People/Characters</h3>
          <p>
            The base structure of the bodies of characters should always be made
            up of the same shapes. All of the brand's colors can be used, as
            long as they are not outlined. The characters’ arms and hands should
            always have a flexible appearance.
          </p>
          <CharacterImage className="character-image" />
          <div className="style__construction">
            <h4>head and hair style construction</h4>
            <div className="head__construction">
              <Face1 />
              <span>+</span>
              <Face2 />
              <span>=</span>
              <Face3 />
            </div>
            <div className="head__style">
              <div className="svg">
                <HeadBase />
                <p className="p-design">Head base structure</p>
              </div>
              <div className="svg svg--2">
                <HairStyle />
                <p className="p-design">Hairstyle</p>
              </div>
              <div className="svg svg--3">
                <FaceStyle />
                <p className="p-design">Final head design</p>
              </div>
            </div>
            <p className="p__structure">
              Here is the base structure for the front view, 3/4 view, and side
              view of a head. There are two variants of a face: One with glasses
              and one without. To create a new head, it is recommended to use
              this base structure for the face, nose, and mouth, and add the
              hair afterwards. Here are 8 examples of hairstyles, of which the
              color can be changed. You can create faces combining head base
              structures and hairstyles.
            </p>
          </div>
        </div>
        <div className="body__structure">
          <h4 className="structure__title">Body structure</h4>
          <p>
            Drawing from these shapes, all base structure of the bodies are
            made.
            <br /> All the brand’s colors can be used as long as they are
            without outlines.
          </p>
          <div className="structure">
            <BodyStructure1 />
            <BodyStructure2 />
            <BodyStructure3 />
            <BodyStructure4 />
            <BodyStructure5 />
          </div>
        </div>
        <div className="arms__hands">
          <h4>arms and hands</h4>
          <p className="arms__explanation">
            The length proportion of the arms is approximately between 1/2 and
            2/5 (in the case that an exaggerated proportion is desired) of the
            size of the body.
          </p>
          <div className="arms__hands__examples">
            <Hands1 />
            <Hands2 />
            <Hands4 />
            <Hands3 />
          </div>
        </div>
        <div className="legs">
          <h4>legs</h4>
          <p className="legs__explanation">
            The length proportion of the legs is approximately 2 to 3 bodies
            long, depending on the shape of the body. Examples of legs in
            different positions:
          </p>
          <div className="legs__examples">
            <Legs1 />
            <Legs2 />
            <Legs3 />
            <Legs4 />
          </div>
        </div>
        <div className="objects">
          <h3>Objects</h3>
          <p className="objects__explanation">
            Objects should be made up of a plain, single-color background
            without an outline, with lines used to define the object. Some
            examples of complementary objects to be used when needed.
          </p>
          <div className="objects__example">
            <Objects1 />
            <Objects2 />
          </div>
        </div>
        <div className="color_in_use">
          <h3>Color in use</h3>
          <p className="color__explanation">
            Examples of colored backgrounds on illustrations. Any of the
            following colors may be used as backgrounds except for in the case
            of illustrations for the Indicius blog, which should not have white
            backgrounds.
          </p>

          <div className="color__examples">
            <Color4 />
            <Color3 />
            <Color2 />
            <Color1 />
            <Color5 />
          </div>
        </div>
        {/* <div className="downloads">
          <h3>Download files</h3>
          <p className="downloads__explanation">
            We have all our illustration in an organized folder.
          </p>
          <a
            href="/"
            rel="noreferrer"
            target="_blank"
            className="button button--indiblue">
            Get those files
          </a>
        </div> */}
      </div>
      {/* LINE SHAPES END */}
    </Layout>
  );
};

export default Illustatrion;
