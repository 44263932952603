// React
import React, { useState, useEffect } from "react";

// Navegacion
import { navigation2 } from "config";

// LOGO INDI
import { ReactComponent as Indiblue } from "assets/icons/indiblue.svg";
import Accordeon from "components/menu-accordeon";
import BrandbookCard from "components/brand-book-card";

const MenuBrandbook = () => {
  // estado de componente
  const [activo, setActivo] = useState(false);

  //funcion que modifica el estado
  const toggleMenu = () => {
    setActivo(!activo);
  };

  useEffect(() => {
    const body = document.querySelector("body");
    const html = document.querySelector("html");
    if (activo) {
      body.classList.add("no-scroll");
      html.classList.add("no-scroll");
    } else {
      body.classList.remove("no-scroll");
      html.classList.remove("no-scroll");
    }
  }, [activo]);

  return (
    <div className="menu">
      <div
        className={
          activo ? "menu__controls menu__controls--active" : "menu__controls"
        }
      >
        <a
          className={activo ? "menu__logo menu__logo--activo" : "menu__logo"}
          href="https://brand.indicius.com"
        >
          <Indiblue />
        </a>
        <button
          className={
            activo ? "menu__toggler menu__toggler--activo" : "menu__toggler"
          }
          type="button"
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>

      <div
        className={
          activo ? "menu__content menu__content--activo" : "menu__content"
        }
      >
        <div className="menu__list">
          {navigation2.map((category, index) => (
            <div className="mb-3">
              <Accordeon
                title={category.name}
                items={category.links}
                key={index}
              />
            </div>
          ))}
        </div>
        <div className="ds__section">
          <BrandbookCard />
        </div>
      </div>
    </div>
  );
};

export default MenuBrandbook;
