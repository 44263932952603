//Styles
import "../assets/styles/main.scss";

// Libraries
import { Helmet } from "react-helmet";

// Components
import ScrollToTop from "components/scroll-to-top/";
import SEO from "components/seo/";
import Footer from "components/footer";
import React from "react";
import MenuBrandbook from "components/menu-brandbook";

const LayoutPrimary = ({ children }) => {
  return (
    <ScrollToTop>
      <Helmet />
      <SEO />
      <div className="layout__wrapper">
        <div className="layout row justify-content-end">
          <div className="layout__col layout__col--sidebar col col-12 col-lg-3">
            <MenuBrandbook />
          </div>
          <div className="layout__content layout__col col col-12 col-md-9">
            <div className="container">{children}</div>
            <div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </ScrollToTop>
  );
};

export default LayoutPrimary;
