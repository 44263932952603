//Layout
import Layout from "../../layouts/layout-primary";

// Components
import SEO from "components/seo/";

const CaseStudies = () => {
  return (
    <Layout>
      <SEO
        title="Writing Case Studies - Indicius Brand Website"
        description="Key principles for writing case studies for Indicius"
      />
      <h1 className="content__title">Case studies & other content</h1>
      <div>
        <p>
          Here are the key principles to follow when writing case studies about
          Indicius projects. And really, these are good principles to keep in
          mind for any kind of content (for example articles, social media
          posts, videos)
        </p>
        <ul className="case__content">
          <li>
            <span className="indiblue">1. </span>Tell a good story.
          </li>
          <li>
            <span className="indiblue">2. </span>Describe your thought process.
          </li>
          <li>
            <span className="indiblue">3. </span>Keep it short.
          </li>
          <li>
            <span className="indiblue">4. </span>Add some personality.
          </li>
          <li>
            <span className="indiblue">5. </span>Keep our values at the front of
            your mind.
          </li>
        </ul>
        <p>
          Find details and more helpful tips in our{" "}
          <span>
            <a href="https://indicius.com/work" className="indi-blue">
              case study template.
            </a>
          </span>
        </p>
      </div>
    </Layout>
  );
};

export default CaseStudies;
