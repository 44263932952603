// React
import React from "react";

// SEO
import SEO from "components/seo/";

// Layout
import Layout from "layouts/layout-primary";

// Components
import AudioCard from "components/audio-card/";
import AudioPlayer from "components/audio-player";

// Tracks1
import Track1Mobile from "assets/icons/sonic-branding/track1-mobile.svg";
import Track1Desktop from "assets/icons/sonic-branding/track1-desktop.svg";

// Track2
import Track2Mobile from "assets/icons/sonic-branding/track2-mobile.svg";
import Track2Desktop from "assets/icons/sonic-branding/track2-desktop.svg";

// Track3
import Track3Mobile from "assets/icons/sonic-branding/track3-mobile.svg";
import Track3Desktop from "assets/icons/sonic-branding/track3-desktop.svg";

// Track4
import Track4Mobile from "assets/icons/sonic-branding/track4-mobile.svg";
import Track4Desktop from "assets/icons/sonic-branding/track4-desktop.svg";

// TRACKS TOPICS
import Track1 from "assets/tracks/track1.mp3";
import Track2 from "assets/tracks/track2.mp3";
import Track3 from "assets/tracks/track3.mp3";
import Track4 from "assets/tracks/track4.mp3";

// SFX TRACKS
import Arrastrar from "assets/tracks/sfx/arrastrar.wav";
import Arrastrar2 from "assets/tracks/sfx/arrastrar2.wav";
import Clack from "assets/tracks/sfx/clack.wav";
import Golpe from "assets/tracks/sfx/golpe.wav";
import Impacto from "assets/tracks/sfx/impacto.wav";
import ImpactoGrave1 from "assets/tracks/sfx/impacto-grave1.wav";
import ImpactoGrave2 from "assets/tracks/sfx/impacto-grave2.wav";
import ImpactoComplejo from "assets/tracks/sfx/impacto-complejo.wav";
import Latido from "assets/tracks/sfx/latido.wav";
import Metal from "assets/tracks/sfx/metal.wav";
import Metal2 from "assets/tracks/sfx/metal2.wav";
import Transition from "assets/tracks/sfx/transition.wav";
import Transition2 from "assets/tracks/sfx/transition2.wav";
import Tic from "assets/tracks/sfx/tic.wav";
import Trick from "assets/tracks/sfx/trick.wav";
import TicTuc from "assets/tracks/sfx/tictuc.wav";
import TucTic from "assets/tracks/sfx/tuctic.wav";
import TipeoLargo from "assets/tracks/sfx/tipeolargo.wav";
import TipeoLargo2 from "assets/tracks/sfx/tipeolargo.wav";
import TipeoSimple from "assets/tracks/sfx/tipeosimple.wav";
import TipeoSimple2 from "assets/tracks/sfx/tipeosimple2.wav";
import Voz1 from "assets/tracks/sfx/voz1.wav";
import Voz2 from "assets/tracks/sfx/voz2.wav";
import Voz3 from "assets/tracks/sfx/voz3.wav";
import Voz4 from "assets/tracks/sfx/voz4.wav";
import SonicLogo from "assets/tracks/sfx/sonic.logo2.5.flac";

// Final logo
import { ReactComponent as Logo } from "assets/icons/indiwhite.svg";

const SonicBranding = () => {
  const tracks = [
    {
      title: "Track 1",
      trackURL: Track1,
      imageMobile: Track1Mobile,
      imageDesktop: Track1Desktop,
      content_1: "Mood: Happy",
      content_2:
        "Associated concepts: We achieved our goal, everything went well. ︎",
      content_3:
        "In use examples: Job search, new team member, short announcements, achievements.",
      description:
        " ︎Description: This option is more playful than uplifting; the organic quality comes from the percussion in the beginning with a more minimalist route. Being the most funky groove with almost no melodic elements makes it a good background for a video without drawing too much attention or making a statement by itself. It has an additional dimension of humanity.",
      download:
        "https://drive.google.com/drive/folders/1K4nosMgbRDAysmF7wWTR4P0E0ZIv3qNE",
    },
    {
      title: "Track 2",
      trackURL: Track2,
      imageMobile: Track2Mobile,
      imageDesktop: Track2Desktop,
      content_1: "Mood: Friendly",
      content_2: "Associated concepts: Challenges, work in progress.︎",
      content_3:
        "In use examples: How we work (our processes), new projects, inspirational quotes.",
      description:
        " ︎Description: Almost exclusively percussive, with an accelerated tempo that serves basically as a wild card for any type of video, especially motion graphics. We particularly chose to find a more 'serious' mood in this track, but not too serious, and also 'exciting'.",
      download:
        "https://drive.google.com/drive/folders/1jeMTEll0W2PMPLqqsvwx8RDcDx3wVfNS",
    },
    {
      title: "Track 3",
      trackURL: Track3,
      imageMobile: Track3Mobile,
      imageDesktop: Track3Desktop,
      content_1: "Mood: Quiet, calming vibes, focus and chill",
      content_2:
        "Associated concepts: Daily work, looking for inspiration, sketching ideas, reading.",
      content_3: "In use examples: Case study summary.",
      description:
        " ︎Description: A track of this style gives us a cooler theme with a mood that goes more towards hiphop-chill. We want to transmit a chill, relaxed vibe when people listen to this track. We have organic elements again, adding humanity to the beat.",
      download:
        "https://drive.google.com/drive/folders/1y5f32g50v-nhKrSId4AYp0wG0wzd9wCE",
    },
    {
      title: "Track 4",
      trackURL: Track4,
      imageMobile: Track4Mobile,
      imageDesktop: Track4Desktop,
      content_1: "Mood: Fun, positive, inviting, exciting",
      content_2:
        "Associated concepts: Something new is coming, innovation, team work.",
      content_3:
        "In use examples: Year summary, inspirational quotes, longer announcements.",
      description:
        " Description: The idea here was to do something lively, but introspective. The use of organic sounds to give a sense of humanity and create a lively mood stays present. It could be used for more serious matters if necessary.",
      download:
        "https://drive.google.com/drive/folders/1gb1hd4a4_spXOUguh3vKk8kk9lxJnn2a",
    },
  ];

  const AudioTracks = [
    {
      description: "arrastrar",
      audio: Arrastrar,
      downloadAudio:
        "https://drive.google.com/file/d/1RFBthm7GxN5M3tUTXDID27V_e09aOuOl/view?usp=drive_link",
    },
    {
      description: "arrastrar 2",
      audio: Arrastrar2,
      downloadAudio:
        "https://drive.google.com/file/d/1modkvLPtqh6vElb7MOqw6iOPem7gC1XK/view?usp=drive_link",
    },
    {
      description: "clack",
      audio: Clack,
      downloadAudio:
        "https://drive.google.com/file/d/1x-3ykU4Irmt2UN-n-mlTWhsa0atlHWoC/view?usp=drive_link",
    },
    {
      description: "golpe",
      audio: Golpe,
      downloadAudio:
        "https://drive.google.com/file/d/1UJPnxG2Ldq5ozAvX9vGLBwp19rawHE3Q/view?usp=drive_link",
    },
    {
      description: "impacto",
      audio: Impacto,
      downloadAudio:
        "https://drive.google.com/file/d/1kYi9VsZZ2wD0kLLPUk0cpaUGYj9mh_J2/view?usp=drive_link",
    },
    {
      description: "impacto grave",
      audio: ImpactoGrave1,
      downloadAudio:
        "https://drive.google.com/file/d/1cMuqu7Z_O11H2TJwOQrRrXHvxmbbZX68/view?usp=drive_link",
    },
    {
      description: "impacto grave 2",
      audio: ImpactoGrave2,
      downloadAudio:
        "https://drive.google.com/file/d/106-jSaPMPFfmqgyFvOpXdf5QTN49HULa/view?usp=drive_link",
    },
    {
      description: "impacto complejo",
      audio: ImpactoComplejo,
      downloadAudio:
        "https://drive.google.com/file/d/1NhJGOcRng7erQHfVY-ba2orRI40WdmKG/view?usp=drive_link",
    },
    {
      description: "latido",
      audio: Latido,
      downloadAudio:
        "https://drive.google.com/file/d/1V7n1G-0IcGFx5dyEpQidPYbiVR2MakOu/view?usp=drive_link",
    },
    {
      description: "metal",
      audio: Metal,
      downloadAudio:
        "https://drive.google.com/file/d/1bwyFiTToHSUtiepLsTAmHV1vD2N6EW0O/view?usp=drive_link",
    },
    {
      description: "metal 2",
      audio: Metal2,
      downloadAudio:
        "https://drive.google.com/file/d/1CUyXpqMqCYBzUzfP_SzrMMmvWIZuS4Op/view?usp=drive_link",
    },
    {
      description: "transicion",
      audio: Transition,
      downloadAudio:
        "https://drive.google.com/file/d/1SEn-jXeBY6z64gz8z7sk91pqvRjjkVju/view?usp=drive_link",
    },
    {
      description: "transicion 2",
      audio: Transition2,
      downloadAudio:
        "https://drive.google.com/file/d/1I2QWAWiExGbTXR_pJMeAl5cojfzr6qUX/view?usp=drive_link",
    },
    {
      description: "tic",
      audio: Tic,
      downloadAudio:
        "https://drive.google.com/file/d/17DqqNshAT5fzr5D7DrAvCZ3vTySDumXT/view?usp=drive_link",
    },
    {
      description: "trick",
      audio: Trick,
      downloadAudio:
        "https://drive.google.com/file/d/1iDMrzMbEisLbOsXmGs1OolJueC9QTFv6/view?usp=drive_link",
    },
    {
      description: "tic tuc",
      audio: TicTuc,
      downloadAudio:
        "https://drive.google.com/drive/folders/1FfsfAYgaeIPGIwkK491xOYCPGdplbJoL",
    },
    {
      description: "tuc tic",
      audio: TucTic,
      downloadAudio:
        "https://drive.google.com/file/d/1vOAWv5SFi7l7CkPimmr5lbqD2rmZfNr8/view?usp=drive_linkk",
    },
    {
      description: "tipeo largo",
      audio: TipeoLargo,
      downloadAudio:
        "https://drive.google.com/file/d/1G7qKCjh6ZEQLv_-l8sBvn2RozvstPGSF/view?usp=drive_link",
    },
    {
      description: "tipeo largo 2",
      audio: TipeoLargo2,
      downloadAudio:
        "https://drive.google.com/file/d/1lAxTCXunQtiWVzbC78JUhwGkFGxaoUaN/view?usp=drive_link",
    },
    {
      description: "tipeo simple",
      audio: TipeoSimple,
      downloadAudio:
        "https://drive.google.com/file/d/1RIsQ5QRflN6RvaJa7I1khN-cynERUQWo/view?usp=drive_link",
    },
    {
      description: "tipeo simple 2",
      audio: TipeoSimple2,
      downloadAudio:
        "https://drive.google.com/file/d/1IzHYA8hDPTwqJ22Xw2p4Ad0_Mj5rOe2f/view?usp=drive_link",
    },
    {
      description: "voz 1",
      audio: Voz1,
      downloadAudio:
        "https://drive.google.com/file/d/1Nq018ywzbNJHm4MIMtXLUHQW5mNWJgO2/view?usp=drive_link",
    },
    {
      description: "voz 2",
      audio: Voz2,
      downloadAudio:
        "https://drive.google.com/file/d/1V-YWRJ_Uxz-aXHlUdiuFx2hzez_N5v0H/view?usp=drive_link",
    },
    {
      description: "voz 3",
      audio: Voz3,
      downloadAudio:
        "https://drive.google.com/file/d/1QtJ5aUjUfE4JA81yyULKcBvJzu-7Rzg6/view?usp=drive_link",
    },
    {
      description: "voz 4",
      audio: Voz4,
      downloadAudio:
        "https://drive.google.com/file/d/1Z8yxQKxZGxEjUGcezBs6GYTX2-CMR1hN/view?usp=drive_link",
    },
  ];

  const animatedTrack = [
    {
      description: "sonic logo 2.5",
      audio: SonicLogo,
      downloadAudio:
        "https://drive.google.com/file/d/1sUnZazu2DUaj_B5xKFNv15Sq9HkZup5K/view?usp=drive_link",
    },
  ];

  return (
    <Layout>
      <SEO
        title="Our Sonic Branding, Custom Tracks & Guidelines - Indicius Brand Website"
        description="Hear our custom sonic branding tracks and see how we use them"
      />
      <div className="sonic__branding">
        <h1 className="sonic__title">Sonic branding</h1>
        <p>
          The purpose of this guide is to standardize the use of our branding
          tracks and SFX sounds. Use the guide as a reference when you are
          creating animations, videos or other type of content that involves
          music.
        </p>
        <h3 className="topics__title">Tracks by topic</h3>
        <p>
          These are the 4 main tracks of Indicius Sonic Branding. They are
          devided according to their mood. All tracks are available both in flac
          (losseless) and mp3 (lighter, less quality) coding format.{" "}
        </p>

        {tracks.map((track, index) => (
          <div className="sonic__track" key={index}>
            <AudioCard {...track} />
          </div>
        ))}

        <div className="branding__use">
          <h3>Instructions for use</h3>
          <p>
            In order to have more control over the duration and moods that are
            established, each piece is divided into different loops of 8
            measures. These loops are able to be combined with each other and
            are constructed in such a way that just by releasing one after the
            other the music works. This allows you to take out sections to make
            the track shorter or duplicate them to extend it. Also, since they
            are 8 measures, you can cut loops exactly in half and they work the
            same way.
          </p>
          <h3 className="sfx__title">SFX Gallery</h3>
          <p>
            Our Sonic Branding also have a variety of customized sounds to be
            used in any animated piece.
          </p>
          <div className="sonic__player row">
            {AudioTracks.map((audio, index) => (
              <div className="col-sm-6" key={index}>
                <AudioPlayer {...audio} />
              </div>
            ))}
          </div>
        </div>

        <div className="sonic__animated">
          <h3>Track for animated logo</h3>
          <p>We have an exclusive track for our animated logo.</p>

          {animatedTrack.map((audio, index) => (
            <div className="animate__track" key={index}>
              <AudioPlayer {...audio} />
            </div>
          ))}

          <p className="in--use">︎▸ In use:</p>
          <div className="sonic__logo">
            <Logo />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SonicBranding;
