//Layout
import Layout from "layouts/layout-primary";

// Components
import SEO from "components/seo/";

//Lottie
import LottieHeader from "components/lottie";

//Components
import IntroComponent from "components/intro-component";

//Messaging Images
import PrinciplesMobile from "assets/icons/introduction/messaging/principles-m.svg";
import PrinciplesDesktop from "assets/icons/introduction/messaging/principles-d.svg";
import BrandVision from "assets/icons/introduction/messaging/brand-vision.svg";
import VoiceChart from "assets/icons/introduction/messaging/voice-chart.svg";
import DoDont from "assets/icons/introduction/messaging/do-dont.svg";
import GrammarMobile from "assets/icons/introduction/messaging/grammar-mobile.svg";
import GrammarDesktop from "assets/icons/introduction/messaging/grammar-desktop.svg";
import CaseStudies from "assets/icons/introduction/messaging/case-studies.svg";
import WordList from "assets/icons/introduction/messaging/word-list.svg";

//Guide lines Images
import LogotypeDesktop from "assets/icons/introduction/guide-lines/logotype-desktop.svg";
import LogotypeMobile from "assets/icons/introduction/guide-lines/logotype-mobile.svg";
import ColorDesktop from "assets/icons/introduction/guide-lines/color-desktop.svg";
import ColorMobile from "assets/icons/introduction/guide-lines/color-mobile.svg";
import TypographyMobile from "assets/icons/introduction/guide-lines/typography-mobile.svg";
import TypographyDesktop from "assets/icons/introduction/guide-lines/typography-desktop.svg";
import MotionMobile from "assets/icons/introduction/guide-lines/motion-mobile.svg";
import MotionDesktop from "assets/icons/introduction/guide-lines/motion-desktop.svg";
import IllustrationMobile from "assets/icons/introduction/guide-lines/illustration-mobile.svg";
import IllustrationDesktop from "assets/icons/introduction/guide-lines/illustration-desktop.svg";
import IconsMobile from "assets/icons/introduction/guide-lines/icons-mobile.svg";
import IconsDesktop from "assets/icons/introduction/guide-lines/icons-desktop.svg";
import SonicBrandingMobile from "assets/icons/introduction/guide-lines/sonic-branding-mobile.svg";
import SonicBrandingDesktop from "assets/icons/introduction/guide-lines/sonic-branding-desktop.svg";

const Intro = () => {
  return (
    <Layout className="--home">
      <SEO
        title="Welcome to the Indicius Brand Website"
        description="An interactive brand guide by Indicius, global digital design agency"
      />
      <div className="intro">
        <div className="lottie__header">
          <LottieHeader />
        </div>

        <h2 className="introduction__subtitle ">
          Welcome to the Indicius Brand Website
        </h2>
        <br />
        <div className="intro__company">
          <div className="intro__italic">
            <p>
              This site brings Indi’s Brand Guide to life in an interactive
              format.
            </p>
            <br></br>
            <p>
              As an ever-growing company, these guidelines set the foundation
              for high-quality, consistent, on-brand designs in all content and
              materials that bear the Indicius logo. This way, no matter which
              of our wonderful designers takes on a task, it will always come
              out
              <span> identifiably</span> Indi.
            </p>
          </div>

          <br />

          <p> This Brand Guide has two main purposes:</p>
          <br />
          <ol>
            <li>
              Use it as a <strong>cheat sheet</strong> for on-brand design: An
              internal tool for reference when creating anything related to the
              Indicius brand
            </li>
            <li>
              Use it as an <strong>example</strong> for clients: A tool to use
              in sales pitches or at the beginning of the design process to help
              clients understand what a Brand Guide is and why it’s important to
              have.
            </li>
          </ol>
          <p>
            Our Brand Guide ensures that each and every design element, from our
            logo to our music, is aligned with our core value of designing for a
            <strong> more human-centered world.</strong>
          </p>
        </div>

        <div className="component__principles">
          <IntroComponent
            img__desktop={PrinciplesDesktop}
            img__mobile={PrinciplesMobile}
            title={"Principles"}
            link={"/principles"}
          />
        </div>

        <div className="messaging row">
          <h3>Messaging</h3>
          <div className="col-sm-6">
            <IntroComponent
              img__desktop={BrandVision}
              img__mobile={BrandVision}
              title={"Brand vision"}
              link={"/brand-vision"}
              className="big bkg"
            />
          </div>

          <div className="pares col-sm-6">
            <div className="vision brand">
              <IntroComponent
                img__desktop={VoiceChart}
                img__mobile={VoiceChart}
                title={"Voice chart"}
                link={"/voice-chart"}
                className="small bkg"
              />
            </div>

            <div>
              <IntroComponent
                img__desktop={DoDont}
                img__mobile={DoDont}
                title={"Do's & Don'ts"}
                link={"/dos-and-dont"}
                className="small bkg"
              />
            </div>
          </div>

          <div className="grammar">
            <IntroComponent
              img__desktop={GrammarDesktop}
              img__mobile={GrammarMobile}
              title={"Grammar & mechanics"}
              link={"/grammar-and-mechanics"}
              className="big bkg"
            />
          </div>

          <div className="col-sm-6">
            <IntroComponent
              img__desktop={CaseStudies}
              img__mobile={CaseStudies}
              title={"Case studies"}
              link={"/case-studies"}
              className="small bkg"
            />
          </div>

          <div className="col-sm-6">
            <IntroComponent
              img__desktop={WordList}
              img__mobile={WordList}
              title={"Word list"}
              link={"/word-list"}
              className="small bkg"
            />
          </div>
        </div>

        <div className="guide-lines">
          <h3>Guidelines</h3>
          <div className="row">
            <div className="col-sm-6">
              <IntroComponent
                img__desktop={LogotypeDesktop}
                img__mobile={LogotypeMobile}
                title={"Logotype"}
                link={"/logotype"}
                className="img100"
              />
            </div>
            <div className="col-sm-6">
              <IntroComponent
                img__desktop={ColorDesktop}
                img__mobile={ColorMobile}
                title={"Color"}
                link={"/color"}
                className="img100"
              />
            </div>
          </div>
          <div>
            <IntroComponent
              img__desktop={TypographyDesktop}
              img__mobile={TypographyMobile}
              title={"Typography"}
              link={"/typography"}
              className="bkg img100"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <IntroComponent
              img__desktop={MotionDesktop}
              img__mobile={MotionMobile}
              title={"Motion"}
              link={"/motion"}
              className="big__motion bkg"
            />
          </div>
          <div className="col-sm-6">
            <IntroComponent
              img__desktop={IconsDesktop}
              img__mobile={IconsMobile}
              title={"Icons"}
              link={"/icons"}
              className="img100"
            />
            <IntroComponent
              img__desktop={IllustrationDesktop}
              img__mobile={IllustrationMobile}
              title={"Illustration"}
              link={"/illustration"}
              className="img100"
            />
          </div>
          <div>
            <IntroComponent
              img__desktop={SonicBrandingDesktop}
              img__mobile={SonicBrandingMobile}
              title={"Sonic Branding"}
              link={"/sonic-branding"}
              className="img100"
            />
          </div>
        </div>

        <div className="introduction__element">
          <h3 className="element__title row">Assets</h3>
          <div className="row">
            <p className="element__subtitle">Messaging</p>
            <div className="element col-12 col-md-6">
              <h4 className="element__name">Brand of vision</h4>
              <p className=" element__paragraph detail">
                Need a refresher on what our customers sound like and what words
                they use? Take a look at the
                <span>
                  <a
                    href="https://www.notion.so/indicius/Indi-Content-59e5579d3bdc44de8413479192b53813#2dc1b2610273452eabe6a8c375fbf44a"
                    target="_blank"
                    rel="noreferrer">
                    {" "}
                    master research playbook.
                  </a>
                </span>
              </p>
            </div>
            <div className="element col-12 col-md-6 alineacion">
              <h4 className="element__name">Case study template</h4>
              <p className=" element__paragraph detail">
                Find details and more helpful tips in our
                <span>
                  {" "}
                  <a
                    href="https://indicius.com/work/indicius"
                    rel="noreferrer"
                    target="_blank">
                    case study template.
                  </a>
                </span>
              </p>
            </div>
          </div>
          <div className="guidelines row">
            <p className="element__subtitle">Guidelines</p>
            <div className="element col-12 col-md-6 ">
              <h4 className="element__name">Logotype</h4>
              <p className=" element__paragraph detail">
                We have all our brand assets in an organized folder.
                <span>
                  {" "}
                  <a
                    href="https://drive.google.com/drive/folders/1S7WopKtiBcybUl-UaGync22dinH-9One"
                    rel="noreferrer"
                    target="_blank">
                    Get those files.
                  </a>{" "}
                </span>
              </p>
            </div>
            <div className="element col-12 col-md-6 alineacion">
              <h4 className="element__name">Typography</h4>
              <p className=" element__paragraph detail">
                Get DM Sans our brand typeface and Archivo our supporting font.
                <br />
                <span>
                  {" "}
                  <a
                    href="https://fonts.google.com/specimen/DM+Sans?query=dm"
                    rel="noreferrer"
                    target="_blank">
                    {" "}
                    Download fonts.
                  </a>{" "}
                </span>
              </p>
            </div>
            <div className="element col-12 col-md-6">
              <h4 className="element__name">icons</h4>
              <p className=" element__paragraph detail">
                We have all our brand assets in an organized folder.
                <span>
                  {" "}
                  <a
                    href="https://drive.google.com/drive/folders/1SeRneZfVLfudvcEfLLs2XTzijfj0iEgn"
                    rel="noreferrer"
                    target="_blank">
                    Get those files
                  </a>{" "}
                </span>
              </p>
            </div>
            {/* <div className="element  col-12 col-md-6 alineacion">
              <h4 className="element__name">Illustration</h4>
              <p className=" element__paragraph detail">
                We have all our illustration in an organized folder.
                <span>
                  {" "}
                  <a href="/" rel="noreferrer" target="_blank">
                    Get those files.
                  </a>{" "}
                </span>
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Intro;
