//Layouts
import Layout from "../../layouts/layout-primary";

// Components
import SEO from "components/seo/";

//Icons
import { ReactComponent as Cultive } from "assets/icons/icons/icon-cultive.svg";
import { ReactComponent as Couriosity } from "assets/icons/icons/icon-couriosity.svg";
import { ReactComponent as Improvement } from "assets/icons/icons/icon-improvement.svg";
import { ReactComponent as Knowledge } from "assets/icons/icons/icon-knowledge.svg";
import { ReactComponent as Matters } from "assets/icons/icons/icon-matters.svg";
import { ReactComponent as Positive } from "assets/icons/icons/icon-positive.svg";
import { ReactComponent as Sense } from "assets/icons/icons/icon-sense.svg";
import { ReactComponent as Strategy } from "assets/icons/icons/icon-strategize.svg";
import { ReactComponent as Build } from "assets/icons/icons/icon-build.svg";
import { ReactComponent as Iterate } from "assets/icons/icons/icon-iterate.svg";
import { ReactComponent as Discover } from "assets/icons/icons/icon-discover-and-research.svg";

const Icons = () => {
  return (
    <Layout>
      <SEO
        title="Illustrated Brand Icons - Indicius Brand Website"
        description="See Indicius’s brand icons and how to create new icons"
      />
      <div className="icons">
        <h1>Icons</h1>
        <h3>Illustrated icons</h3>
        <p>
          We have a system of illustrated, personalized icons that are used to
          show our culture and work processes.
          <br />
          Each icon is made up of a colored shape and a line that completes the
          definition of the shape.
        </p>
        <br></br>
        <div className="icons__96">
          <Improvement />
          <Sense />
          <Knowledge />
          <Matters />
          <Positive />
          <Cultive />
          <Couriosity />
        </div>
        <p className="detail">
          For icons that are 96x96 px, a 2px line should be used.
        </p>
        <div className="icons__64">
          <Strategy />
          <Build />
          <Iterate />
          <Discover />
        </div>
        <p className="detail">
          For icons that are 64x64 px, a 1px line should be used.
        </p>
        <div className="icons__complementary">
          <h3 className="icons__subtitle">Complementary icon system</h3>
          <p>TBD </p>
        </div>
      </div>
    </Layout>
  );
};

export default Icons;
