//React
import React, { useState, useRef, useEffect } from "react";

// Icons
import { ReactComponent as IconPlay } from "assets/icons/sonic-branding/play.svg";
import { ReactComponent as IconPause } from "assets/icons/sonic-branding/pause.svg";

//Download Button
import { ReactComponent as ArrowDownload } from "assets/icons/sonic-branding/arrow-download.svg";

const AudioPlayer = (props) => {
  //STATE
  const [play, setPlay] = useState(false);
  const [duration] = useState(0);
  const [currentTime, setCurrentTime] = useState("00:00");

  //AUDIO REF
  const audioPlayer = useRef(); // Ref to audio player
  const progressBar = useRef(); // Ref to progress bar
  const animationRef = useRef(); // Ref to the animation progress bar

  //PROPS
  const { audio, description, downloadAudio } = props;

  useEffect(() => {
    play ? audioPlayer.current.play() : audioPlayer.current.pause();
  }, [play]);

  useEffect(() => {
    const getTime = (event) => {
      const {
        target: { currentTime: _currentTime },
      } = event;
      setCurrentTime(calculateTime(_currentTime));
    };

    const element = audioPlayer.current;

    element.addEventListener("timeupdate", getTime);

    return () => element.removeEventListener("timeupdate", getTime);
  }, []);

  useEffect(() => {
    const handleEnding = () => {
      setPlay(false);
      setCurrentTime("00:00");
      animationRef.current = requestAnimationFrame(resetProgressBar);
    };

    const element = audioPlayer.current;

    element.addEventListener("ended", handleEnding);

    return () => element.removeEventListener("ended", handleEnding);
  });

  //Esta funcion calcula el tiempo en min:seg;
  const calculateTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  };

  const togglePlayPause = () => {
    const prevValue = play;
    setPlay(!prevValue);
    if (!prevValue) {
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  };

  const whilePlaying = () => {
    progressBar.current.value =
      (audioPlayer.current.currentTime * 100) / audioPlayer.current.duration;
    changePlayerCurrentTime();
    animationRef.current = requestAnimationFrame(whilePlaying);
  };

  const changeRange = () => {
    audioPlayer.current.currentTime =
      audioPlayer.current.duration * (progressBar.current.value / 100);
    changePlayerCurrentTime();
  };

  const changePlayerCurrentTime = () => {
    progressBar.current.style.setProperty(
      "--seek-before-width",
      `${(progressBar.current.value / duration) * 100} %`
    );
  };

  const resetProgressBar = () => {
    progressBar.current.value = 0;
    changePlayerCurrentTime();
    animationRef.current = requestAnimationFrame(resetProgressBar);
  };

  return (
    <div className="audio__container">
      <p className="audio__description">{description}</p>
      <div className="audio__player">
        <button
          type="button"
          className="card__play"
          onClick={() => togglePlayPause()}
        >
          {play ? <IconPause /> : <IconPlay />}
        </button>

        <div className="audio__items">
          <audio ref={audioPlayer} src={audio} preload="metadata"></audio>
          <div>
            <input
              ref={progressBar}
              type="range"
              className="progress__bar"
              defaultValue="0"
              onChange={changeRange}
            />
          </div>
          <div className="audio__time">{currentTime}</div>
          <div className="audio__arrow">
            <a href={downloadAudio} rel="noreferrer" target="_blank">
              <ArrowDownload />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
