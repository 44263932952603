//React
import React, { useEffect, useState, useRef } from "react";

//Arrow
import { ReactComponent as ArrowDownload } from "assets/icons/sonic-branding/download-arrow.svg";

// Icons
import { ReactComponent as IconPlay } from "assets/icons/sonic-branding/play.svg";
import { ReactComponent as IconPause } from "assets/icons/sonic-branding/pause.svg";

const AudioCard = (props) => {
  const audioPlayer = useRef();

  const [play, setPlay] = useState(false);
  const [currentTime, setCurrentTime] = useState("00:00");

  const {
    imageMobile,
    imageDesktop,
    title,
    content_1,
    content_2,
    content_3,
    trackURL,
    description,
    download,
  } = props;

  //Esta funcion calcula el tiempo en min:seg;
  const calculateTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  };

  // Play or Pauses the audio element
  useEffect(() => {
    play ? audioPlayer.current.play() : audioPlayer.current.pause();
  }, [play]);

  // Logs current time of the audio element
  useEffect(() => {
    const getTime = (event) => {
      const {
        target: { currentTime: _currentTime },
      } = event;
      setCurrentTime(calculateTime(_currentTime));
    };

    const element = audioPlayer.current;

    element.addEventListener("timeupdate", getTime);

    return () => element.removeEventListener("timeupdate", getTime);
  }, []);

  // Handles 'play' and 'pause' for the audio player.
  // Pauses all other audio players on 'play'.
  useEffect(() => {
    const handlePlay = () => {
      const audioPlayers = document.querySelectorAll("audio");

      audioPlayers.forEach((_audioPlayer) => {
        if (_audioPlayer !== audioPlayer.current && !_audioPlayer.isPaused) {
          _audioPlayer.pause();
        }
      });

      setPlay(true);
    };

    const handlePause = () => {
      setPlay(false);
    };
    const element = audioPlayer.current;

    element.addEventListener("play", handlePlay);
    element.addEventListener("pause", handlePause);

    return () => {
      element.removeEventListener("play", handlePlay);
      element.removeEventListener("pause", handlePause);
    };
  }, []);

  const togglePlayPause = () => {
    const prevValue = play;
    setPlay(!prevValue);
    if (!prevValue) {
      audioPlayer.current.play();
    } else {
      audioPlayer.current.pause();
    }
  };

  return (
    <React.Fragment>
      <div className={"audio-card row"}>
        <div className="card__image col-12 col-md-4">
          <div className="audio__time">{currentTime}</div>
          <img src={imageMobile} alt="Indicius" className="image__mobile" />
          <img src={imageDesktop} alt="Indicius" className="image__desktop" />
          <button
            type="button"
            className="card__play"
            onClick={() => togglePlayPause()}
          >
            {play ? <IconPause /> : <IconPlay />}
          </button>
          <audio controls ref={audioPlayer}>
            <source src={trackURL} type="audio/mpeg"></source>
          </audio>
        </div>
        <div className="audio__list col-12 col-md-8">
          <div className="audio__title">
            <h3>{title}</h3>
            <a href={download} rel="noreferrer" target="_blank">
              <ArrowDownload />
            </a>
          </div>
          <p>︎▸ {content_1}</p>
          <p>︎▸ {content_2}</p>
          <p>︎▸ {content_3}</p>
        </div>
      </div>

      <p className="audio__description">▸{description}</p>
    </React.Fragment>
  );
};

export default AudioCard;
