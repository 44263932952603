//layouts
import Layout from "../../layouts/layout-primary";

// Components
import SEO from "components/seo/";

const brandVision = () => {
  return (
    <Layout>
      <SEO
        title="Our Brand Vision - Indicius Brand Website"
        description="Key concepts we want to convey through our copy and content"
      />
      <div className="brand">
        <h1 className="content__title">Brand Vision</h1>

        <h3 className="brand__title">Our key brand messaging concepts</h3>
        <ul className="brand__concepts">
          <li>• We build together</li>
          <li>• We’re always in motion</li>
          <li>• We’re humans helping humans meet their goals</li>
        </ul>

        <h3 className="brand__title">USPs</h3>
        <p>
          We create brand identities and digital products that are uniquely
          human-centered.
          <br></br>
          <br></br>
          We talk about our work culture a lot. That’s because we believe that
          innovation happens when our team and clients can thrive and do their
          best work.
        </p>

        <h3 className="brand__title">Elevator pitch</h3>
        <p>
          We’re a creative agency that believes in designing for a more
          human-centered world through collaboration. Using co-creation
          workshops, remote collaboration tools, and user research techniques,
          we create exceptional branding solutions and digital products. We
          bring the strategy, talent, and team spirit to make it all happen.
        </p>

        <h3 className="brand__title">Voice of customer research</h3>
        <p>
          Need a refresher on what our customers sound like and what words they
          use? Take a look at the{" "}
          <a href="https://www.notion.so/indicius/Indi-Content-59e5579d3bdc44de8413479192b53813#2dc1b2610273452eabe6a8c375fbf44a">
            master research playbook.
          </a>
        </p>
      </div>
    </Layout>
  );
};

export default brandVision;
