import React from "react";
import { NavLink } from "react-router-dom";

// Icon
import { ReactComponent as Arrow } from "assets/icons/menu/arrow-menu.svg";

const Accordion = ({ title, items }) => {
  const toggleAccordion = (event) => {
    const { target } = event;

    const accordion = target.closest(".accordion"); // Buscamos el div con clase `.accordion` más cercano al toggler (es el div padre)
    if (!accordion.classList.contains("active")) {
      accordion.classList.add("active");
    } else {
      accordion.classList.remove("active");
    }

    const activeAccordions = document.querySelectorAll(".accordion.active");

    activeAccordions.forEach((activeAccordion) => {
      if (activeAccordion !== accordion) {
        activeAccordion.classList.remove("active");
      }
    });
  };

  return (
    <div className="accordion">
      <div className="list__page__title">
        <button
          type="button"
          className="title"
          onClick={(event) => toggleAccordion(event)}
        >
          {title} <Arrow />
        </button>
      </div>
      <div className="list__items">
        {items.map((item) => (
          <ul>
            <li>
              <NavLink exact to={item.link} activeClassName="link--active">
                {item.name}
              </NavLink>
            </li>
          </ul>
        ))}
      </div>
    </div>
  );
};

export default Accordion;
