// Layout
import Layout from "layouts/layout-primary";

// Components
import SEO from "components/seo/";

// TEAM IMAGES
import team_on_board from "assets/images/photography/team-on-board.png";
import team_on_boardx2 from "assets/images/photography/team-on-board@2x.png";
import josie from "assets/images/photography/josie.png";
import josiex2 from "assets/images/photography/josie@2x.png";
import all_team from "assets/images/photography/all-team.png";
import all_teamx2 from "assets/images/photography/all-team@2x.png";

// BAD EXAMPLES
import bad_example_board from "assets/images/photography/bad-example1.png";
import bad_example_boardx2 from "assets/images/photography/bad-example1@2x.png";
import bad_example2 from "assets/images/photography/bad-example2.png";
import bad_example2x2 from "assets/images/photography/bad-example2@2x.png";

const Photography = () => {
  return (
    <Layout>
      <SEO
        title="Photography & Guidelines - Indicius Brand Website"
        description="Types of photos we like to include in our content at Indicius"
      />
      <div className="photography">
        <h1 className="content__title">Photography</h1>
        <p className="photo__paragraph">
          Our photos show our culture. We show the work we do on the team and
          our processes, but we also show how we enjoy our work just as much as
          we do our free time.
        </p>
        <h3>Good examples</h3>
        <img
          src={team_on_board}
          alt="Indicius"
          srcSet={team_on_boardx2 + " 2x"}
        />
        <p className="photo__description">
          • Show our processes and the way we work
        </p>
        <img src={josie} alt="Indicius" srcSet={josiex2 + " 2x"} />
        <p className="photo__description">
          • Focuses on people, showing casual situations
        </p>
        <img src={all_team} alt="Indicius" srcSet={all_teamx2 + " 2x"} />
        <p className="photo__description">
          • The people aren't looking at the camera
        </p>
        <h3 className="bad__examples">Bad examples</h3>
        <img
          src={bad_example_board}
          alt="Indicius"
          srcSet={bad_example_boardx2 + " 2x"}
        />
        <p className="red photo__description">• Black and white pictures.</p>
        <img
          src={bad_example2}
          alt="Indicius"
          srcSet={bad_example2x2 + " 2x"}
        />
        <p className="red photo__description">
          • Stock photos or photos with a similar style
        </p>
      </div>
    </Layout>
  );
};

export default Photography;
