//Layout
import Layout from "../layouts/layout-primary";

const Home = () => {
  return (
    <Layout>
      <h1 className="content__title">Introduction</h1>
      <p>
        Think of this as a cheat sheet on how to write consistent on-brand copy
        for anything that comes from Indicius.
      </p>
      <br></br>
      <p>
        These guidelines exist to help our big idea of designing for a more
        human-centered world, together, trickle down into any and all of our
        content so that even when different people write copy, it feels like
        it's coming from the same team.
      </p>
      <br></br>
      <p>
        It should give you the confidence to hit the ground running for copy on
        our site, in our emails, in our Instagram posts—anywhere—is aligned with
        the new Indicius branding and newly defined messaging.
      </p>
    </Layout>
  );
};

export default Home;
