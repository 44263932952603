//Arrow icon
import { ReactComponent as Arrow } from "assets/icons/arrow.svg"

const IntroComponent = ({
  className,
  img__mobile,
  img__desktop,
  title,
  link,
}) => {
  return (
    <a className={"intro__component " + className} href={link}>
      <div className="intro__images">
        <img src={img__mobile} alt="Indicius" className="mobile" />
        <img src={img__desktop} alt="Indicius" className="desktop" />
      </div>

      <div className="intro__content">
        <p className="intro__title">{title}</p>
        <span className="intro__button">
          <Arrow />
        </span>
      </div>
    </a>
  )
}

export default IntroComponent
