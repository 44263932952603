//React
import React, { useState, useEffect } from "react";

// Libraries
import { useLocation } from "react-router-dom";

// Config
import { navigation } from "config";

//Arrow component
import { ReactComponent as Arrow } from "assets/icons/arrow.svg";

const FooterNavigation = () => {
  const { pathname } = useLocation();
  const [anterior, setAnterior] = useState(null);
  const [siguiente, setSiguiente] = useState(null);

  useEffect(() => {
    navigation.forEach((category, categoryIndex) => {
      const { name: categoryName, links } = category;

      links.forEach((link, index) => {
        const { link: url } = link;

        if (pathname === url) {
          // Si existe anterior
          if (index !== 0) {
            const anterior = links[index - 1];
            anterior.category = categoryName;

            setAnterior(anterior);
          } else {
            // Si existe categoria anterior
            if (navigation[categoryIndex - 1]) {
              const categoriaAnterior = navigation[categoryIndex - 1];
              const anterior =
                categoriaAnterior.links[categoriaAnterior.links.length - 1];
              anterior.category = navigation[categoryIndex - 1].name;

              setAnterior(anterior);
            }
          }

          // Si existe siguiente
          if (index + 1 !== links.length) {
            const siguiente = links[index + 1];
            siguiente.category = categoryName;

            setSiguiente(siguiente);
          } else {
            // Si existe categoria siguiente
            if (navigation[categoryIndex + 1]) {
              const siguiente = navigation[categoryIndex + 1].links[0];
              siguiente.category = navigation[categoryIndex + 1].name;
              setSiguiente(siguiente);
            }
          }
        }
      });
    });
  }, [pathname]);

  return (
    <div className="footer__navigation">
      {anterior ? (
        <a className="navigation__item" href={anterior.link}>
          <div className="navigation__arrow navigation__arrow--left">
            <Arrow />
          </div>
          <p>
            {anterior.category}
            <br />
            <span className="fw--400">{anterior.name}</span>
          </p>
        </a>
      ) : (
        <div></div>
      )}

      {siguiente && (
        <a
          className="navigation__item navigation__item--right"
          href={siguiente.link}
        >
          <p>
            {siguiente.category}
            <br />
            <span className="fw--400">{siguiente.name}</span>
          </p>
          <div className="navigation__arrow navigation__arrow--two">
            <Arrow />
          </div>
        </a>
      )}
    </div>
  );
};

export default FooterNavigation;
