import React from "react";

// Icon
import { ReactComponent as DesignSystem } from "assets/icons/menu/ds-icon.svg";
import { ReactComponent as DesignSystemDesktop } from "assets/icons/menu/ds-icon-desktop.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/menu/icon-download.svg";

const DesignSystemCard = () => {
  return (
    <div className="ds__card__container">
      <DesignSystem className="ds-icon-mobile" />
      <DesignSystemDesktop className="ds-icon-desktop" />
      <div className="ds__content">
        <p className="color--midnight ms-sm-3 me-sm-3 mb-md-2">
          Discover Indicius Design System
        </p>
        <a
          href="/design-system"
          className="d-flex align-items-center justify-content-between color--indiblue"
        >
          Open Design System <DownloadIcon />
        </a>
      </div>
    </div>
  );
};

export default DesignSystemCard;
