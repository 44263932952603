import Layout from "layouts/layout-primary";

// Components
import SEO from "components/seo/";

const Color = () => {
  return (
    <Layout>
      <SEO
        title="Our Brand Colors - Indicius Brand Website"
        description="List of Indicius main brand colors and hex codes"
      />
      <div className="color">
        <h1>Color</h1>
        <h3 className="color__subtitle">Brand color</h3>

        <div className="color__box bg--indiblue row">
          <div className="box__color col-12 col-sm-5 color--white">
            <p>Indiblue</p>
            <p>#3098F3</p>
          </div>

          <div className="box__size col-12 col-sm-7">
            <div className="item item--first color--white fw--700">
              <p></p>
              <p>16PX</p>
              <p>24PX</p>
            </div>

            <div className="item color--white">
              <p>White text</p>
              <p>Not legible</p>
              <p>Pass</p>
            </div>

            <div className="item item--last color--stout">
              <p>Stout text</p>
              <p>Pass</p>
              <p>Pass</p>
            </div>
          </div>
        </div>
        <h3 className="neutrals">Secondary</h3>
        {/* SECONDARY COLOR MIDNIGHT START */}
        <div className="color__box bg--midnight row">
          <div className="box__color col-12 col-sm-5 color--white">
            <p>Midnight</p>
            <p>#082C4E</p>
          </div>

          <div className="box__size col-12 col-sm-7">
            <div className="item item--first color--white fw--700">
              <p></p>
              <p>16PX</p>
              <p>24PX</p>
            </div>

            <div className="item color--white">
              <p>White text</p>
              <p>Pass</p>
              <p>Pass</p>
            </div>

            <div className="item item--last color--indiblue">
              <p>Indiblue text</p>
              <p>Pass</p>
              <p>Pass</p>
            </div>
          </div>
        </div>
        {/* SECONDARY COLOR MIDNIGHT END */}

        {/* SECONDARY COLOR SUPERNOVA START */}
        <div className="color__box bg--supernova row">
          <div className="box__color col-12 col-sm-5 color--midnight">
            <p>Supernova</p>
            <p>#FFCC00</p>
          </div>

          <div className="box__size col-12 col-sm-7">
            <div className="item item--first fw--700 color--midnight">
              <p></p>
              <p>16PX</p>
              <p>24PX</p>
            </div>

            <div className="item color--white">
              <p>White text</p>
              <p>Not legible</p>
              <p>Not legible</p>
            </div>

            <div className="item color--stout">
              <p>Stout text</p>
              <p>Pass</p>
              <p>Pass</p>
            </div>

            <div className="item item--last color--indiblue">
              <p>Indiblue text</p>
              <p>Not legible</p>
              <p>Not legible</p>
            </div>
          </div>
        </div>
        {/* SECONDARY COLOR SUPERNOVA END */}

        {/* SECONDARY COLOR WATERMELON START */}
        <div className="color__box bg--watermelon row">
          <div className="box__color col-12 col-sm-5 color--midnight">
            <p>Watermelon</p>
            <p>#FE6571</p>
          </div>

          <div className="box__size col-12 col-sm-7">
            <div className="item item--first fw--700 color--midnight">
              <p></p>
              <p>16PX</p>
              <p>24PX</p>
            </div>

            <div className="item color--white">
              <p>White text</p>
              <p>Not legible</p>
              <p>Not legible</p>
            </div>

            <div className="item color--stout">
              <p>Stout text</p>
              <p>Pass</p>
              <p>Pass</p>
            </div>

            <div className="item item--last color--indiblue">
              <p>Indiblue text</p>
              <p>Not legible</p>
              <p>Not legible</p>
            </div>
          </div>
        </div>
        {/* SECONDARY COLOR WATERMELON END */}

        {/* SECONDARY COLOR CREAM START */}
        <div className="color__box bg--cream row">
          <div className="box__color col-12 col-sm-5 color--midnight">
            <p>Cream</p>
            <p>#F2EAE2</p>
          </div>

          <div className="box__size col-12 col-sm-7">
            <div className="item item--first fw--700 color--midnight">
              <p></p>
              <p>16PX</p>
              <p>24PX</p>
            </div>

            <div className="item color--white">
              <p>White text</p>
              <p>Not legible</p>
              <p>Not legible</p>
            </div>

            <div className="item color--stout">
              <p>Stout text</p>
              <p>Pass</p>
              <p>Pass</p>
            </div>

            <div className="item item--last color--indiblue">
              <p>Indiblue text</p>
              <p>Not legible</p>
              <p>Not legible</p>
            </div>
          </div>
        </div>
        {/* SECONDARY COLOR CREAM END */}

        {/* NEUTRALS COLOR START */}
        <h3 className="neutrals">Neutrals</h3>
        {/* WHITE COLOR START */}
        <div className="color__box bg--white n-white row">
          <div className="box__color col-12 col-sm-5 color--midnight">
            <p>White</p>
            <p>#FFFFFF</p>
          </div>

          <div className="box__size col-12 col-sm-7">
            <div className="item item--first fw--700 color--midnight">
              <p></p>
              <p>16PX</p>
              <p>24PX</p>
            </div>

            <div className="item color--stout">
              <p>Stout text</p>
              <p>Pass</p>
              <p>Pass</p>
            </div>

            <div className="item item--last color--indiblue">
              <p>Indiblue text</p>
              <p>Not legible</p>
              <p>Pass</p>
            </div>
          </div>
        </div>
        {/* WHITE COLOR END  */}

        {/* CLOUDY COLOR START */}
        <div className="color__box n-cloudy row">
          <div className="box__color col-12 col-sm-5 color--midnight">
            <p>Cloudy</p>
            <p>#D9D9DA</p>
          </div>

          <div className="box__size col-12 col-sm-7">
            <div className="item item--first fw--700 color--midnight">
              <p></p>
              <p>16PX</p>
              <p>24PX</p>
            </div>

            <div className="item color--white">
              <p>White text</p>
              <p>Not legible</p>
              <p>Not legible</p>
            </div>

            <div className="item color--stout">
              <p>Stout text</p>
              <p>Pass</p>
              <p>Pass</p>
            </div>

            <div className="item item--last color--indiblue">
              <p>Indiblue text</p>
              <p>Not legible</p>
              <p>Not legible</p>
            </div>
          </div>
        </div>
        {/* CLOUDY COLOR END  */}

        {/* STONE COLOR START */}
        <div className="color__box bg--stone row">
          <div className="box__color col-12 col-sm-5 color--white">
            <p>Stone</p>
            <p>#747481</p>
          </div>

          <div className="box__size col-12 col-sm-7">
            <div className="item item--first fw--700 color--midnight">
              <p></p>
              <p>16PX</p>
              <p>24PX</p>
            </div>

            <div className="item color--white">
              <p>White text</p>
              <p>Pass</p>
              <p>Pass</p>
            </div>

            <div className="item color--stout">
              <p>Stout text</p>
              <p>Not legible</p>
              <p>Pass</p>
            </div>

            <div className="item item--last color--indiblue">
              <p>Indiblue text</p>
              <p>Not legible</p>
              <p>Not legible</p>
            </div>
          </div>
        </div>
        {/* STONE COLOR END */}

        {/* STOUT COLOR START */}
        <div className="color__box bg--stout row">
          <div className="box__color col-12 col-sm-5 color--white">
            <p>Stout</p>
            <p>#05051E</p>
          </div>

          <div className="box__size bs--stout col-12 col-sm-7 color--white">
            <div className="item item--first item--stout fw--700">
              <p></p>
              <p>16PX</p>
              <p>24PX</p>
            </div>

            <div className="item item--stout  color--white">
              <p>White text</p>
              <p>Pass</p>
              <p>Pass</p>
            </div>

            <div className="item item--last item--stout color--indiblue">
              <p>Indiblue text</p>
              <p>Pass</p>
              <p>Pass</p>
            </div>
          </div>
        </div>
        {/* STOUT COLOR END  */}
      </div>
    </Layout>
  );
};

export default Color;
