import Layout from "../../layouts/layout-primary";

// Components
import SEO from "components/seo/";

const WordList = () => {
  return (
    <Layout>
      <SEO
        title="Language and Words To Use or Avoid - Indicius Brand Website"
        description="List of words and phrases we like and dislike for on-brand copy"
      />
      <div className="word">
        <h1 className="content__title">Word list</h1>
        <p>
          Add to these lists anytime you have a discussion about words that
          “sound like our voice” or “don’t sound like us.”
        </p>
        {/* Word or phrases we like */}
        <h3>Words or phrases we like</h3>
        <ul>
          <li>• Human-centered design</li>
          <li>• Co-creation workshops</li>
          <li>• We work with you, not for you</li>
          <li>
            • Innovation happens when both our team and our clients can grow and
            thrive.
          </li>
          <li>• Family spirit</li>
          <li>• Outcomes</li>
          <li>• Team spirit</li>
          <li>• Tailored processes</li>
        </ul>
        {/* Words or phrases to avoid */}
        <h3>Words or phrases to avoid</h3>
        <ul>
          <li>• One pixel at a time</li>
        </ul>
      </div>
    </Layout>
  );
};

export default WordList;
