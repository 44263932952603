//Layouts
import SEO from "components/seo";
import Layout from "../../layouts/layout-primary";
const Grammar = () => {
  return (
    <Layout>
      <SEO
        title="Grammar & Mechanics When Writing Copy - Indicius Brand Website"
        description="Basic grammar rules for writing copy for Indicius content"
      />
      <div className="grammar">
        <h1 className="content__title">Grammar and mechanics</h1>
        <p>
          This is not an exhaustive list—just an overview of rules that turn up
          a lot in Indi content. When in doubt, talk to the team about adding
          new rules, and use the{" "}
          <span>
            <a
              className="grammar-span"
              href="https://styleguide.mailchimp.com/"
              target="_blank"
              rel="noreferrer"
            >
              Mailchimp style guide
            </a>
          </span>{" "}
          as a reference.
        </p>
        <div className="grammar__content">
          {/* Capitalizacion */}
          <h3 className="content__subtitle">Capitalization</h3>
          <ul>
            <li>• Use title case for headings and main navigation ONLY</li>
            <li>• Use sentence case for subheadings</li>
            <li>
              <span className="item-azul italic">
                <span className="bullet">○ </span>This is sentence case
              </span>
            </li>
            <li>
              <span className="item-rojo italic">
                <span className="bullet">○ </span>This is Not Sentence Case
              </span>
            </li>
            <li>• No punctuation unless the title is a question</li>
          </ul>
          {/* Buttons and call to action */}

          <h3 className="content__subtitle">Buttons and calls to action</h3>
          <ul>
            <li>• Always include an action</li>
            <li>• Always use sentence case in buttons</li>
            <li>
              <span className="item-azul italic">
                <span className="bullet">○ </span>Start here
              </span>
            </li>
            <li>
              <span className="item-rojo">
                <span className="bullet">○ </span>Not:{" "}
                <span className="italic">Start Here</span>
              </span>
            </li>
          </ul>
          {/* Links */}
          <h3 className="content__subtitle">Links</h3>
          <ul>
            <li>
              • Write sentences naturally and link relevant keywords. Don’t
              include articles (a, the, an) in hyperlinked text.
            </li>
            <li>
              <span className="item-azul italic">
                <span className="bullet">○ </span>Read the{" "}
                <span className="subrayado">case study.</span>
              </span>
            </li>
            <li>
              <span className="item-rojo">
                <span className="bullet">○ </span>Not:{" "}
                <span className="italic">Read </span>
                <span className="subrayado italic">the case study.</span>
              </span>
            </li>
            <li>
              • Avoid phrases like "click here." Instead, use language that
              describes the content you're linking to.
            </li>
          </ul>
          {/* Alt text */}
          <h3 className="content__subtitle">Alt Text</h3>
          <ul>
            <li>• Every image should have alt text.</li>
            <li>
              • Alt text briefly describes an image in one or two sentences.
            </li>
          </ul>
          {/* Contractions */}
          <h3 className="content__subtitle">Contractions</h3>
          <ul>
            <li>• Use common contractions (can’t, won’t, don’t, shouldn’t).</li>
            <li>
              • Contractions make things easier to read, eliminate friction.
            </li>
            <li>• Don’t use weird contractions (this’ll, what’ll, what’d).</li>
          </ul>
          {/* Active Voice */}
          <h3 className="content__subtitle">Active Voice</h3>
          <ul>
            <li>
              • We're (active) humans. Use active voice whenever possible,
              unless you’re trying to focus on the action over the subject.{" "}
            </li>
            <li>
              <span className="item-azul">
                <span className="bullet">○ </span>Active voice:{" "}
                <span className="italic">
                  We’re sending you a confirmation email.
                </span>
              </span>
            </li>
            <li>
              <span className="item-rojo">
                <span className="bullet">○ </span>Passive voice:{" "}
                <span className="italic">
                  {" "}
                  A confirmation email is being sent to you.
                </span>
              </span>
            </li>
          </ul>
          {/* First vs. third person */}
          <h3 className="content__subtitle">First vs. third person</h3>
          <ul>
            <li>• Use first person in most places</li>
            <li>
              <span className="item-azul italic">
                <span className="bullet">○ </span>We believe in human-centered
                design.{" "}
              </span>
            </li>
            <li>
              <span className="item-rojo">
                <span className="bullet">○ </span>Not:{" "}
                <span className="italic">
                  Indicius believes in human-centered design.
                </span>
              </span>
            </li>
            <li className="italic">
              • It’s ok to write in third person when you want to communicate
              more formal things about the company, like meta descriptions for
              search engines{" "}
            </li>
            <li>
              <span className="item-azul">
                <span className="bullet">○ </span>Indicius is a creative agency
                based in Buenos Aires, Argentina.
              </span>
            </li>
          </ul>
          {/* Oxford comma */}
          <h3 className="content__subtitle">Oxford comma</h3>
          <ul>
            <li>• Use the oxford comma (serial comma)</li>
            <li>
              <span className="item-azul italic">
                <span className="bullet">○ </span>Designers, developers, and
                copywriters
              </span>
            </li>
            <li>
              <span className="item-rojo">
                <span className="bullet">○ </span>Not:{" "}
                <span className="italic">
                  Designers, developers and copywriters
                </span>
              </span>
            </li>
          </ul>
          {/* Periods */}
          <h3 className="content__subtitle">Periods</h3>
          <ul>
            <li>
              • Use periods at the end of full sentences, except for these
              instances:
            </li>
            <li>
              <span className="item-azul">
                <span className="bullet">○ </span>No periods in subheads or
                headers
              </span>
            </li>
            <li>
              <span className="item-azul">
                <span className="bullet">○ </span>No periods in bullets or list
                items
              </span>
            </li>
          </ul>
          {/* Money */}
          <h3 className="content__subtitle">Money</h3>
          <ul>
            <li>
              • Use the dollar sign before the amount, and only use a decimal
              point and number of cents if greater than 0:
            </li>
            <li>
              <span className="item-azul italic">
                <span className="bullet">○ </span>$17
              </span>
            </li>
            <li>
              <span className="item-azul italic">
                <span className="bullet">○ </span>$239.99
              </span>
            </li>
            <li>
              • When writing about Argentine currency, always use AR$ before
              every number
            </li>
            <li>
              <span className="item-azul italic">
                <span className="bullet">○ </span>The app costs AR$100 to
                download
              </span>
            </li>
            <li>
              <span className="item-rojo">
                <span className="bullet">○ </span>Not:{" "}
                <span className="italic">
                  The app costs 100 pesos to download
                </span>
              </span>
            </li>
            <li>
              • When writing about US currency to an international audience
              (like on our website or in an article), always use US$ before the
              number. When writing for a US audience, it’s fine to leave out the
              “US” part.
            </li>
          </ul>
          {/* Time */}
          <h3 className="content__subtitle">Time</h3>
          <ul>
            <li>Use 12-hour time, and include am or pm always.</li>
            <li>
              <span className="item-azul italic">
                <span className="bullet">○ </span>10:30am
              </span>
            </li>
            <li>
              <span className="item-azul italic">
                <span className="bullet">○ </span>1 pm
              </span>
            </li>
          </ul>
          {/* Emojis */}
          <h3 className="content__subtitle">Emojis</h3>
          <ul>
            <li>• Do we like emojis?</li>
            <li>Use emojis in social media captions, emails. </li>
            <li>What emoji family do we use?</li>
          </ul>

          {/* Slang & Terminology */}
          <h3 className="content__subtitle">Slang & Terminology</h3>
          <ul>
            <li>
              Using slang or design-specific terminology is fine, just make sure
              you define everything, especially when you’re writing for a
              non-design audience.
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default Grammar;
