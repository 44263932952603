const BackToTop = () => {
  // const mybutton = document.getElementById("btn");

  // When the user clicks on the button, scroll to the top of the document
  const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  return (
    <div className="backtotop" id="btn">
      <button className="button" onClick={topFunction}>
        Back to top
      </button>
    </div>
  );
};

export default BackToTop;
